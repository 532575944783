import { Box, Grid, Stack, Typography } from '@mui/material';
import SelectableExpertTile from '../../shared/SelectableExpertTile';
import { Dispatch, SetStateAction } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { DefineAgents } from '../components/DefineAgents';
import { Agent } from '../../../types/agents';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

type SelectExpertListProps = {
  accountNames: string[];
  selectedAccountNames: string[];
  setSelectedAccountNames?: Dispatch<SetStateAction<string[]>>;
  agents: Agent[];
  setAgents?: Dispatch<SetStateAction<Agent[]>>;
  disabled?: boolean;
};

/**
 * Displays the accountNames in a grid of SelectableExpertTile components. For
 * logged in users, it ignores the selected prop and relies on if the account is
 * trusted or not. The logged in user can change the count on status though. For
 * guests, the user can select and deselect the account. This looks the same as
 * counting on and off, but is not saved anywhere.
 * @param param0
 * @returns
 */
export default function SelectExpertList({
  accountNames,
  selectedAccountNames,
  setSelectedAccountNames,
  agents,
  setAgents,
  disabled = false,
}: SelectExpertListProps) {
  const { user } = useAuth();

  const handleSelected = (selected: boolean, accountName: string) => {
    if (!setSelectedAccountNames) return;
    if (selected) {
      setSelectedAccountNames((prev) =>
        prev.includes(accountName) ? prev : [...prev, accountName],
      );
    } else {
      setSelectedAccountNames((prev) =>
        prev.filter((name) => name !== accountName),
      );
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'center'}
        sx={{ width: 1 }}
      >
        <WorkspacePremiumIcon color="primary" sx={{ mr: 1 }} />
        <Typography fontSize={24} fontWeight={600}>
          Your Expert Panel
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography color="GrayText" fontWeight={500}>
          {selectedAccountNames.length} of {accountNames.length} experts
          selected
        </Typography>
        <Typography color="primary" fontWeight={500}>
          Click an expert card to include/exclude them from your analysis
        </Typography>
      </Box>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        wrap="wrap"
        width={1}
        sx={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {/* Example elements */}
        {accountNames.map((accountName) => (
          <Grid item key={accountName}>
            <SelectableExpertTile
              accountName={accountName}
              selected={selectedAccountNames.includes(accountName)}
              setSelected={(selected) => handleSelected(selected, accountName)}
            />
          </Grid>
        ))}
      </Grid>
      {user?.role === 'admin' && (
        <Box>
          <Typography>Agents are ADMIN only right now</Typography>
          <DefineAgents maxAgents={5} agents={agents} setAgents={setAgents} />
        </Box>
      )}
    </Stack>
  );
}
