import { Box } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserOnboardingMutation } from 'src/hooks/user-hooks';
import { OnboardingStep } from '../../../types/user';
import { useReferrer } from 'src/lib/hooksLib';
import { useAddTrustedAccountsByNameMutation } from '../../../hooks/trusted-accounts-hooks';
import { useEffect, useMemo } from 'react';
import { LoadingSpinner } from '../../../components/loading-spinner';
import log from 'loglevel';

// This is separate from the default experts on the analysis page. Might be a
// good idea to combine them.
const defaultSuggestedExperts = ['ewg', 'gimmethegoodstuff', 'prop-65'];

/**
 * Automatically selects defaultSuggestedExperts and your referrer. Doesn't show
 * anything but a loading spinner. For a more fully featured expert selector see
 * ChooseExperts.tsx
 * @returns
 */
export default function ChooseDefaultExperts() {
  const { user } = useAuth();
  const { mutateAsync: userMutate } = useUpdateUserOnboardingMutation();
  const navigate = useNavigate();

  const [referrer] = useReferrer();

  const accountsToAdd = useMemo(() => {
    // Deduplicate and remove empty values
    const accounts = [referrer, ...defaultSuggestedExperts];
    const combinedAccounts = Array.from(new Set(accounts.filter((x) => x)));
    return combinedAccounts;
  }, [referrer]);

  log.debug({ accountsToAdd });

  const { mutate: addAccountsMutate } = useAddTrustedAccountsByNameMutation();

  useEffect(() => {
    if (!user) return;
    if (!accountsToAdd || accountsToAdd.length === 0) return;
    addAccountsMutate(
      { account_names: accountsToAdd },
      {
        onSuccess: async () => {
          log.debug('Successfully added accounts, now updating user');
          user.onboarding = OnboardingStep.COMPLETE;
          await userMutate(user);
          log.debug('Successfully updated user, redirect to /analyze-page');
          navigate('/analyze-page');
        },
      },
    );
  }, [user, accountsToAdd, addAccountsMutate, userMutate, navigate]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: 8,
      }}
    >
      <LoadingSpinner />
    </Box>
  );
}
