// Guest analysis limit helpers
const GUEST_ANALYSIS_KEY = 'guest_analysis_timestamp';
const ANALYSIS_COOLDOWN_MS = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds

export const canGuestAnalyze = () => {
  const lastAnalysis = localStorage.getItem(GUEST_ANALYSIS_KEY);
  if (!lastAnalysis) return true;

  const timeSinceLastAnalysis = Date.now() - parseInt(lastAnalysis);
  return timeSinceLastAnalysis >= ANALYSIS_COOLDOWN_MS;
};

// For testing, you can enter this into the console to record a guest analysis:
// localStorage.setItem('guest_analysis_timestamp', Date.now().toString());
export const recordGuestAnalysis = () => {
  localStorage.setItem(GUEST_ANALYSIS_KEY, Date.now().toString());
};
