import { Grid, Card, CardContent, Typography, Box, alpha } from '@mui/material';
// You can import any icon from @mui/icons-material or use your own
import HistoryIcon from '@mui/icons-material/History';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';

export default function GettingStarted() {
  const cardsData = [
    {
      title: 'Analyze Products',
      description:
        'Start analyzing products or ingredient lists with expert insights.',
      linkText: 'Get started',
      icon: <AutoAwesomeIcon sx={{ fontSize: 26, color: 'primary.main' }} />,
      to: '/analyze-page',
    },
    {
      title: 'Previous Analyses',
      description: 'Review your analysis history and saved reports.',
      linkText: 'View history',
      icon: <HistoryIcon sx={{ fontSize: 26, color: 'primary.main' }} />,
      to: '/profile/analyses',
    },
    {
      title: 'Expert Panel',
      description: 'Customize your panel of trusted experts and sources.',
      linkText: 'Manage experts',
      icon: (
        <PeopleAltOutlinedIcon sx={{ fontSize: 26, color: 'primary.main' }} />
      ),
      to: '/profile/experts',
    },
  ];

  return (
    <Box>
      <Typography fontSize={24} fontWeight={600} sx={{ mb: 1 }}>
        Getting Started
      </Typography>
      <Grid container spacing={2}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              component={Link}
              to={card.to}
              sx={{
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                textDecoration: 'none',
              }}
            >
              {/* Purple (primary) box with icon */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                  mt: 2,
                  ml: 2,
                }}
              >
                {card.icon}
              </Box>

              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {card.description}
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <Typography
                    color="primary.main"
                    sx={{ textDecoration: 'none', fontWeight: 500 }}
                  >
                    {card.linkText} &gt;
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
