import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Stack,
} from '@mui/material';
// You can import any icon from @mui/icons-material or use your own
import { Link } from 'react-router-dom';
import { useSearchProductAnalyses } from '../../../hooks/ai/search-product-analyses-hook';
import { useAuth } from '../../../hooks/use-auth';
import { RouterLink } from '../../../components/router-link';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export default function RecentAnalyses() {
  const { user } = useAuth();
  const analysisQuery = useSearchProductAnalyses({
    account_id: user.active_account_id,
    page_size: 2,
    page: 1,
  });

  const analyses = analysisQuery.data?.analyses;
  const noAnalyses = analyses && analyses.length === 0;

  return (
    <Box>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography fontSize={24} fontWeight={600} sx={{ mb: 1 }}>
          Recent Analyses
        </Typography>
        <RouterLink to="/profile/analyses" sx={{ textDecoration: 'none' }}>
          View All &gt;
        </RouterLink>
      </Stack>
      {noAnalyses ? (
        <NoAnalyses />
      ) : (
        <Grid container spacing={2}>
          {analyses &&
            analyses.map((analysis, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  variant="outlined"
                  component={Link}
                  to={`/analyze-page/${analysis.analysis_id}`}
                  sx={{
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    textDecoration: 'none',
                  }}
                >
                  {analysis.image_url ? (
                    <CardMedia
                      sx={{ height: 300 }}
                      image={analysis.image_url}
                    />
                  ) : (
                    <ImageFallback />
                  )}
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      color="grey"
                      sx={{ alignSelf: 'flex-end' }}
                    >
                      {formatDate(analysis.created_at)}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {analysis.brand || '(No Brand)'}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {analysis.title ||
                        analysis.ingredients
                          ?.map((i) => i.name)
                          ?.join(',')
                          ?.slice(0, 100) ||
                        '(No Product Title)'}
                    </Typography>
                    <Box sx={{ mt: 'auto' }}>
                      <Typography
                        color="primary.main"
                        sx={{ textDecoration: 'none', fontWeight: 500 }}
                      >
                        View Analysis &gt;
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}

const NoAnalyses = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      mb={2}
      spacing={1}
    >
      <AutoAwesomeIcon sx={{ fontSize: 25, mb: 2 }} />
      <Typography fontWeight={500}>
        You haven't created any analyses yet.{' '}
      </Typography>
      <Typography>Once you do, you'll see the most recent here.</Typography>
    </Stack>
  );
};

const ImageFallback = () => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '22px',
          height: 300,
          backgroundColor: 'grey.100',
        },
      ]}
    >
      No Image
    </Box>
  );
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString + 'Z'); // Append 'Z' to indicate UTC time
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
