import {
  Box,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Stack } from '@mui/system';
import log from 'loglevel';
import AccountAvatar from './AccountAvatar';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { RouterLink } from '../../components/router-link';
import { useGetPublicProfile } from '../../hooks/account-hooks';
import ScienceIcon from '@mui/icons-material/Science';
import { AIIngredientsType, AIProductsType } from '../../types/account';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';

interface ThinExpertTileProps {
  accountName: string;
  to?: string;
  selected?: boolean;
  setSelected?: (boolean) => void;
  sx?: SxProps<Theme>;
  displayOnly?: boolean;
}

/**
 * The account is not supplied, a skeleton will be returned instead
 *
 * selected is ignored for logged in users
 * @param props
 * @returns
 */
export default function SelectableExpertTile({
  accountName,
  to,
  selected = false,
  setSelected = () => {},
  displayOnly = false,
  sx = {
    width: 300,
    height: 155,
  },
}: ThinExpertTileProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const navigate = useNavigate();

  const {
    data: account,
    isLoading,
    isError,
  } = useGetPublicProfile(accountName);

  const handleAvatarClick = () => {
    if (!account || !to) return;
    navigate(`/p/${accountName}`);
  };

  if (!isLoading && !isError && !account) {
    log.warn('ThinExpertTile: no account found', accountName);
    return null;
  }

  const canAnalyzeIngredients =
    account?.ai_ingredients_type !== AIIngredientsType.NONE &&
    !!account?.ai_ingredients_type;

  const canAnalyzeProducts =
    account?.ai_products_type !== AIProductsType.NONE &&
    !!account?.ai_products_type;

  log.trace('ExpertTile render', account);

  return (
    <Paper
      sx={[
        {
          borderRadius: 2,
          border: '1px solid #DCDCE3',
          p: { xs: 1, sm: 2 },
          cursor: to ? 'pointer' : 'default',
          transition: 'box-shadow 0.2s',
          '&:hover': {
            boxShadow: !displayOnly && `0px 4px 8px rgba(0, 0, 0, 0.5)`,
          },
          ...(selected &&
            !displayOnly && {
              boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.5)`,
            }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      elevation={2}
      onClick={() => setSelected(!selected)}
    >
      <Stack direction={'row'} alignItems="center" spacing={{ xs: 1, sm: 1.5 }}>
        {/* Avatar Image */}
        <Box
          sx={{
            position: 'relative',
            pointerEvents: displayOnly ? 'none' : undefined,
          }}
          component={to ? RouterLink : 'div'}
          to={to ? to : undefined}
        >
          <AccountAvatar
            account={account}
            size={isSm ? 50 : 40}
            onClick={handleAvatarClick}
          />
          <Box
            sx={{
              position: 'absolute',
              top: -4,
              right: -8,
              alignSelf: 'flex-start',
            }}
          >
            {account && (
              <ExpertSelect
                selected={selected}
                setSelected={setSelected}
                displayOnly={displayOnly}
              />
            )}
          </Box>
        </Box>

        {/* Title */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            width: { sm: 1 },
            px: 1,
          }}
        >
          <Typography
            noWrap
            component={to ? Link : undefined}
            to={to ? to : undefined}
            sx={{
              fontSize: 17,
              fontWeight: 600,
              textAlign: 'left',
              lineHeight: 1,
              textDecoration: 'none',
              color: 'black.main',
            }}
          >
            {isLoading ? <Skeleton animation="wave" /> : account?.display_name}{' '}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: 'greyCustom.main',
              textAlign: 'left',
            }}
          >
            {!account?.account_name ? (
              <Skeleton animation="wave" />
            ) : (
              '@' + account.account_name
            )}
          </Typography>
          {/* Icons for expert analysis abilities */}
          <Stack direction="row" spacing={0.5}>
            {canAnalyzeIngredients && <AbilityIcon ability="ingredients" />}
            {canAnalyzeProducts && <AbilityIcon ability="products" />}
            <Typography sx={{ fontSize: 16 }}>{'\u00A0'}</Typography>
          </Stack>
        </Box>
      </Stack>
      {account?.descriptor && (
        <Typography
          fontSize={14}
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '@supports not (-webkit-line-clamp: 1)': {
              display: 'block', // Fallback for Firefox
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap', // Single-line truncation for unsupported browsers
            },
          }}
        >
          {account.descriptor}
        </Typography>
      )}
    </Paper>
  );
}

const AbilityIcon = ({ ability = 'none' }) => {
  const tooltips = {
    none: 'No special abilities',
    ingredients: 'Can analyze ingredients',
    products: 'Can analyze products',
  };
  const icons = {
    none: <FactCheckIcon color="disabled" sx={{ fontSize: 16 }} />,
    ingredients: <ScienceIcon color="disabled" sx={{ fontSize: 16 }} />,
    products: <FactCheckIcon color="disabled" sx={{ fontSize: 16 }} />,
  };
  return <Tooltip title={tooltips[ability]}>{icons[ability]}</Tooltip>;
};

const ExpertSelect = ({ selected, setSelected, displayOnly = false }) => {
  const col = selected ? 'secondary.main' : 'primary.main';

  return (
    <Box
      sx={{
        height: 25,
        width: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: col,
        cursor: displayOnly ? 'default' : 'pointer',
      }}
      onClick={() => setSelected(!selected)}
    >
      {selected ? (
        <CheckIcon sx={{ fontSize: 23, color: '#FFFFFF' }} />
      ) : (
        <AddIcon sx={{ fontSize: 23, color: '#FFFFFF' }} />
      )}
    </Box>
  );
};
