import {
  Avatar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import log from 'loglevel';

export function ProfileSelector({ onChange = () => {} }) {
  let { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Change the current user's active account
  const mutateProfile = useMutation(
    (account_id) => {
      return API.put('trustable', '/user/set-active-account', {
        body: { account_id },
      });
    },
    {
      onSuccess: (data) => {
        log.debug(data);
      },
      onSettled: (data, error, variables, context) => {
        log.debug({ data, error, variables, context });
        queryClient.invalidateQueries();
      },
    },
  );

  const handleProfileClick = () => {
    handleProfileClose();
    onChange();
    navigate('/profile');
  };

  const AccountMenuItem = ({ account, onClick }) => {
    const handleClick = async () => {
      if (account.account_id !== user.active_account_id) {
        log.debug('Change to account', account);
        await mutateProfile.mutateAsync(account.account_id);
        handleProfileClick();
        return;
      }
      onClick();
    };

    return (
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <Avatar
            sx={{ width: 20, height: 20 }}
            alt={account.display_name}
            src={account.avatar_url}
          />
        </ListItemIcon>
        {account.account_name}
      </MenuItem>
    );
  };

  // See https://mui.com/material-ui/react-app-bar/ "App bar with menu"
  // and https://mui.com/material-ui/react-menu/ "Account menu"
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  // TODO: When changing profiles, the useAuth user object does not change.
  // I think Miheer removed the functionality I had for that

  const multipleAccounts = user?.accounts?.length > 1;

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 2 }}>
      <Box component={Link} to="profile" onClick={onChange}>
        <Avatar
          sx={{ width: 35, height: 35 }}
          alt={user?.active_account?.display_name}
          src={user?.active_account?.avatar_url}
        />
      </Box>
      <Stack
        direction="row"
        color="primary"
        alignItems="center"
        gap={0}
        onClick={multipleAccounts ? handleMenu : handleProfileClick}
      >
        <Typography
          color="primary"
          sx={{
            minWidth: 'max-content',
            fontWeight: 700,
            fontSize: '15px',
          }}
        >
          My Profile
        </Typography>
        {multipleAccounts && <ArrowDropDownIcon fontSize="small" />}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleProfileClose}
        onClick={() => {}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user?.accounts?.map((a) => (
          <AccountMenuItem
            account={a}
            onClick={handleProfileClose}
            key={a.account_id}
          />
        ))}
      </Menu>
    </Stack>
  );
}
