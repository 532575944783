import log from 'loglevel';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import {
  AnalysisStatus,
  ExpertDefinition,
  Ingredient,
} from '../../types/saved_product_analyses';

export type SearchAnalysesResponse = {
  account_names?: string[];
  analysis_id: string;
  brand?: string;
  title?: string;
  image_url?: string;
  ingredients?: Ingredient[];
  experts: ExpertDefinition[];
  url?: string;
  status: AnalysisStatus;
  created_at: string;
  row: number;
};

function searchProductAnalyses(
  account_names?: string[],
  account_id?: string,
  guest_id?: string,
  page_size?: number,
  page?: number,
  query?: string,
): Promise<{ analyses: SearchAnalysesResponse[]; n?: number }> {
  log.debug('Fetching saved product analyses for', account_names);
  const sp = new URLSearchParams();
  if (account_id) sp.set('account_id', account_id);
  if (guest_id) sp.set('guest_id', guest_id);
  if (account_names) sp.set('account_names', account_names.join(','));
  if (page_size) sp.set('page_size', page_size.toString());
  if (page) sp.set('page', page.toString());
  if (query) sp.set('query', query);

  const apiPath = `${
    account_id ? '' : '/guest'
  }/product-analyses?${sp.toString()}`;
  return API.get('trustable', apiPath, {});
}

// TODO: This should really be updated to not return the full analysis. It's actually
// easy to go over the payload limit.
// See about deprecating this and using hooks in analysis-search-hooks.tsx
type UseSearchProductAnalysesParams = {
  account_names?: string[];
  account_id?: string;
  guest_id?: string;
  enabled?: boolean;
  page_size?: number;
  page?: number;
  query?: string;
};

export function useSearchProductAnalyses({
  account_names,
  account_id,
  guest_id,
  enabled = true,
  page_size = 15,
  page = 1,
  query,
}: UseSearchProductAnalysesParams) {
  const inputsReady =
    !!guest_id ||
    !!account_id ||
    (Array.isArray(account_names) && account_names.length > 0);

  const review = useQuery(
    [
      'search-analyses',
      account_names,
      account_id,
      guest_id,
      page_size,
      page,
      query,
    ],
    () =>
      searchProductAnalyses(
        account_names,
        account_id,
        guest_id,
        page_size,
        page,
        query,
      ),
    {
      enabled: inputsReady && enabled,
      staleTime: Infinity,
      keepPreviousData: true,
      onSuccess: (data) => {
        log.debug('useSearchProductAnalyses:', data);
      },
      onError: (error) => logError(error),
    },
  );
  return review;
}
