import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

interface SectionContainerProps {
  children?: ReactNode;
  minHeight?: string | number;
  backgroundColor?: string;
  sx?: SxProps<Theme>;
}

export default function SectionContainer({
  children,
  minHeight = 'auto',
  backgroundColor = 'white',
  sx = [],
  ...rest
}: SectionContainerProps) {
  return (
    <Box minHeight={minHeight} sx={{ backgroundColor: backgroundColor }}>
      <Box
        maxWidth={{ sm: 720, md: 1236 }}
        width={1}
        margin={'0 auto'}
        paddingX={{ xs: 0.75, sm: 2 }}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
}
