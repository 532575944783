import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import logoUrl from '../../../assets/logos/CountOn_primary.png';
import smallLogoUrl from '../../../assets/logos/CountOn_small.svg';
import { Link } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { ProfileSelector } from '../../Main/components/ProfileSelector';
import { useSegment } from 'src/contexts/segment-context';
import AddExtensionButton from '../../../pages/home/components/AddExtensionButton';
import EmailIcon from '@mui/icons-material/Email';

export default function AnalysisTopbar({ onSidebarOpen }) {
  const { isAuthenticated, isExtInstalled } = useAuth();

  const handleEmailClick = () => {
    const email = 'support@joincounton.com';
    window.location.href = `mailto:${email}`;
  };

  return (
    <Box>
      {/* Desktop Top Nav */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 1,
          height: 74,
        }}
      >
        <Box display={'flex'} component={Link} to="/">
          <Box component={'img'} src={logoUrl} height={74} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {isAuthenticated && (
          <>
            <NavButton to="analysis-faq" color="black">
              FAQs
            </NavButton>
            <Button
              size="medium"
              startIcon={<EmailIcon />}
              sx={{
                minWidth: 'max-content',
                fontWeight: 600,
                fontSize: '15px',
                color: 'black',
              }}
              onClick={handleEmailClick}
            >
              Get Help
            </Button>
          </>
        )}
        {isAuthenticated ? (
          <>
            <ProfileSelector />
            {!isExtInstalled && (
              <AddExtensionButton
                gtmParams={{ location: 'topbar' }}
                sx={{ px: 2, height: 42 }}
              />
            )}
          </>
        ) : (
          <LogInOrSignUp />
        )}
      </Stack>

      {/* Mobile Top Nav */}
      <Box display={{ xs: 'block', md: 'none' }} paddingTop={2}>
        <Box display="flex" justifyContent="space-between">
          <Box component={Link} to="/">
            <Box component="img" src={smallLogoUrl} height={55} />
          </Box>

          <IconButton onClick={() => onSidebarOpen()} aria-label="Menu">
            <MenuIcon sx={{ color: 'black', fontSize: 40 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

const LogInOrSignUp = () => {
  const segment = useSegment();

  const handleClick = (loc) => {
    segment.track('CTA Clicked', {
      extension: false,
      category: 'nav_item',
      cta: loc,
    });
  };

  return (
    <Stack direction="row" spacing={2}>
      {' '}
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to="login"
        size="medium"
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => handleClick('Log in')}
      >
        Log in
      </Button>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="signup"
        size="medium"
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => handleClick('Sign up')}
      >
        Sign up
      </Button>
    </Stack>
  );
};

const NavButton = ({
  children,
  to,
  variant = 'text',
  color = 'primary',
  onClick = () => {},
}: {
  children: React.ReactNode;
  to: string;
  variant?: 'text' | 'contained' | 'outlined';
  color?: string;
  onClick?: () => void;
}) => {
  const segment = useSegment();
  const handleClick = () => {
    segment.track('CTA Clicked', {
      extension: false,
      category: 'nav_item',
      cta: to,
    });
    onClick();
  };
  return (
    <Button
      variant={variant}
      component={Link}
      to={to}
      size="medium"
      sx={{
        minWidth: 'max-content',
        fontWeight: 600,
        fontSize: '15px',
        color,
      }}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};
