import { useEffect, useState } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import log from 'loglevel';

import { LoadingSpinner } from 'src/components/loading-spinner';
import { useAuth } from 'src/hooks/use-auth';
import SectionContainer from 'src/components/SectionContainer';
import r0 from 'src/assets/dashboard/chrome-cta/Product-0.png';
import r1 from 'src/assets/dashboard/chrome-cta/Product-1.png';
import r2 from 'src/assets/dashboard/chrome-cta/Product-2.png';
import AddExtensionButton from '../home/components/AddExtensionButton';
import { VoidHandler } from 'src/types/shared';
import { useRedirectLink } from 'src/hooks/redirect-hooks';
import { useNavigate } from 'react-router-dom';
import { isMobileSafari } from 'react-device-detect';
import GettingStarted from './components/GettingStarted';
import RecentAnalyses from './components/RecentAnalyses';
import YourExpertPanel from './components/YourExpertPanel';

/**
 * The "ext" flag means this profile is being viewed via the "/r" referral route
 * so the account_name of this page should be saved as the referring account in case
 * this person creates an account
 *
 * @param {*} param0
 * @returns
 */

export default function Dashboard() {
  const auth = useAuth();
  const { user, isExtInstalled } = auth;
  const { getRedirect, clearRedirect } = useRedirectLink();
  const navigate = useNavigate();

  useEffect(() => {
    const path = getRedirect();
    if (path) {
      log.info(`Detected redirect! Clearing and redirecting to ${path}`);
      clearRedirect();
      navigate(path);
    }
  }, [getRedirect, clearRedirect, navigate]);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [isExtensionCtaClosed, setIsExtensionCtaClosed] = useState(
    localStorage.getItem('isExtensionCtaClosed') === 'true',
  );

  useEffect(() => {
    document.title = 'CountOn Dashboard';
  }, []);

  if (!user?.active_account) return <LoadingSpinner />;
  const yourAccount = user.active_account;

  log.trace('Dashboard render');

  const shouldShowExtensionCta =
    isSm && !isExtInstalled && !isExtensionCtaClosed;

  const onExtensionCtaClose = () => {
    setIsExtensionCtaClosed(true);
    localStorage.setItem('isExtensionCtaClosed', 'true');
  };

  return (
    <SectionContainer
      minHeight="850px"
      backgroundColor="pastel.light"
      sx={{ py: { xs: 5, sm: 5 } }}
    >
      {!isMobileSafari && shouldShowExtensionCta && (
        <ExtensionCta onExtensionCtaClose={onExtensionCtaClose} />
      )}
      <Stack direction="column" spacing={3}>
        <Typography fontSize={32} fontWeight={600}>
          Welcome back, {yourAccount.display_name || yourAccount.account_name}!
        </Typography>
        <GettingStarted />
        <RecentAnalyses />
        <YourExpertPanel />
        {/*<RecommendedExperts yourAccount={yourAccount} />*/}
      </Stack>
    </SectionContainer>
  );
}

const ExtensionCta = ({
  onExtensionCtaClose,
}: {
  onExtensionCtaClose: VoidHandler;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      position="relative"
      sx={{
        borderRadius: 4,
        backgroundColor: 'primary.main',
        width: 1,
        height: 180,
        mb: 5,
        px: 6,
      }}
    >
      <Close
        id="close_icon"
        cursor="pointer"
        sx={{ position: 'absolute', color: 'white.main', right: 10, top: 10 }}
        onClick={onExtensionCtaClose}
      />
      <Box>
        <Typography color="white.main" fontSize={24} fontWeight={600}>
          Shop like normal
        </Typography>
        <Typography
          color="white.main"
          fontSize={18}
          fontWeight={500}
          sx={{ maxWidth: 270 }}
        >
          Recommendations you trust, everywhere you shop
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: 210,
          height: 1,
          overflow: 'hidden',
        }}
      >
        <img
          src={r0}
          alt=""
          style={{ position: 'absolute', top: 20, left: 20 }}
        />
        <img
          src={r1}
          alt=""
          style={{ position: 'absolute', top: 70, left: 120 }}
        />
        <img
          src={r2}
          alt=""
          style={{ position: 'absolute', top: '67%', left: 0 }}
        />
      </Box>
      <Box
        sx={{ border: '2px solid white', borderRadius: 4, overflow: 'hidden' }}
      >
        <AddExtensionButton gtmParams={{ location: 'dashboard' }} />
      </Box>
    </Stack>
  );
};
