import log from 'loglevel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useSegment } from '../../../contexts/segment-context';
import { useGetPublicProfile } from '../../../hooks/account-hooks';
import SentimentIcon from '../../../assets/icons/SentimentIcon';
import {
  ExpertDefinition,
  ExpertIngredientAnalysis,
  ExpertProductAnalysis,
  ExpertScore,
  ExpertType,
  SavedProductAnalysis,
} from 'src/types/saved_product_analyses';
import EvaluateWidget from '../shared/EvaluateWidget';
import { useAuth } from '../../../hooks/use-auth';
import IngredientAudio from './AudioWidget';
import IngredientMosaic from './IngredientMosaic';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductImage from '../../shared/ProductImage';
import ShowMoreMarkdownBox from '../../shared/ShowMoreMarkdownBox';
import { Info } from '@mui/icons-material';
import CountOnAvatar from '../../../components/avatar/CountOnAvatar';

export type IngredientsArray = {
  ingredient: string;
  experts: {
    [expertAccountName: string]: ExpertIngredientAnalysis;
  };
  index: number;
}[];

type ProductSummaryWidgetProps = {
  analysis: SavedProductAnalysis;
};
export function ProductSummaryWidget({ analysis }: ProductSummaryWidgetProps) {
  const analysis_id = analysis.analysis_id;
  const segment = useSegment();
  const analysisRef = useRef<HTMLDivElement>(null);

  const handleCopyClick = async () => {
    try {
      // See https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
      const html = analysisRef.current.innerHTML;
      const text = analysisRef.current.innerText;
      const htmlBlob = new Blob([html], { type: 'text/html' });
      const textBlob = new Blob([text], { type: 'text/plain' });
      const data = [
        new ClipboardItem({ 'text/html': htmlBlob, 'text/plain': textBlob }),
      ];
      await navigator.clipboard.write(data);
      segment.track('Copy Analysis Clicked', {
        brand: analysis.brand,
        title: analysis.title,
      });
    } catch (e) {
      // Some older browsers don't support clipboard.write
      prompt('Copy error:', e.message);
      log.warn('Could not copy text', e);
    }
  };

  if (!analysis) return null;

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{ position: 'relative', width: 1, maxWidth: 1000 }}
      ref={analysisRef}
    >
      {analysis.result.product_summaries && (
        <ExpertProductSummaries analysis={analysis} />
      )}
      {analysis.result.ingredients &&
        Object.keys(analysis.result.ingredients).length > 0 && (
          <ExpertIngredientSummaries
            analysis={analysis}
            analysis_id={analysis_id}
          />
        )}
    </Stack>
  );
}

/*
  CHILD COMPONENTS
*/

type ExpertIngredientSummariesProps = {
  analysis: SavedProductAnalysis;
  analysis_id: string;
};
function ExpertIngredientSummaries({
  analysis,
  analysis_id,
}: ExpertIngredientSummariesProps) {
  const [expanded, setExpanded] = useState(true);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const [ingredients, setIngredients] = useState<IngredientsArray>(null);
  const [shownIngredients, setShownIngredients] =
    useState<IngredientsArray>(ingredients);

  useEffect(() => {
    if (!analysis) return;

    // Convert ingredients object from server into an array
    const ing = Object.keys(analysis.result.ingredients).map(
      (ingredient, i) => ({
        ingredient: ingredient,
        experts: analysis.result.ingredients[ingredient],
        index: i,
      }),
    );
    setIngredients(ing);
    setShownIngredients(ing);
  }, [analysis]);

  return (
    <PaperAnalyze sx={{ p: { xs: 0.25, sm: 0.5 }, width: 1 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 24,
              fontWeight: 500,
            }}
          >
            Ingredient Summaries
          </Typography>
        </AccordionSummary>

        {analysis && ingredients && ingredients.length > 0 && (
          <AccordionDetails>
            <Box>
              <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                <IngredientMosaic
                  ingredients={ingredients}
                  shownIngredients={shownIngredients}
                  setShownIngredients={setShownIngredients}
                />
              </Box>
              <Stack direction="column" spacing={2} sx={{ minHeight: 1200 }}>
                {shownIngredients.length === 0 && (
                  <Box
                    sx={{
                      width: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      pt: 5,
                    }}
                  >
                    <Typography>(No ingredients match the filters)</Typography>
                  </Box>
                )}
                {shownIngredients.map((ing, i) => (
                  <Box key={ing.ingredient}>
                    <SingleIngredientSummary
                      name={ing.ingredient}
                      product_analysis_id={analysis_id}
                      experts={ing.experts}
                    />
                    {i < shownIngredients.length - 1 && (
                      <Divider aria-hidden="true" sx={{ mt: 2 }} />
                    )}
                  </Box>
                ))}
              </Stack>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </PaperAnalyze>
  );
}

type ExpertProductSummariesProps = {
  analysis: SavedProductAnalysis;
};
function ExpertProductSummaries({ analysis }: ExpertProductSummariesProps) {
  const [expanded, setExpanded] = useState(true);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  // Replace your existing useEffect with this useMemo
  const { shownSummaries, hiddenSummaries } = useMemo(() => {
    const shown: Record<string, any> = {};
    const hidden: Record<string, any> = {};

    if (!analysis || !analysis.result?.product_summaries) {
      return { shownSummaries: {}, hiddenSummaries: {} };
    }

    Object.entries(analysis.result.product_summaries).forEach(
      ([account_name, accountSummary]: [string, any]) => {
        const summaryText = accountSummary.product_summary as string;
        const conclusionMarker = '**Conclusion**';
        const conclusionIndex = summaryText.indexOf(conclusionMarker);
        let conclusion = summaryText;

        if (conclusionIndex !== -1) {
          conclusion = summaryText
            .substring(conclusionIndex + conclusionMarker.length)
            .trim();
        } else if (summaryText.toLowerCase().trim() === 'no content') {
          conclusion = 'not found';
        } else {
          conclusion = summaryText;
        }

        if (conclusion.toLowerCase().startsWith('not found')) {
          hidden[account_name] = {
            ...accountSummary,
            product_summary: conclusion,
          };
        } else {
          shown[account_name] = {
            ...accountSummary,
            product_summary: conclusion,
          };
        }
      },
    );

    return { shownSummaries: shown, hiddenSummaries: hidden };
  }, [analysis]);

  return (
    <PaperAnalyze sx={{ p: { xs: 0.25, sm: 0.5 }, width: 1 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 24,
              fontWeight: 500,
              // This is an ugly hack, but I can't get noWrap to work inside
              // an accordion with specifying a width like this.
              maxWidth: { xs: 275, sm: 500, md: 700 },
            }}
          >
            {analysis.brand} {analysis.title}
          </Typography>
        </AccordionSummary>

        {analysis && (
          <AccordionDetails>
            <ProductInformation analysis={analysis} />
            <Stack direction="column" spacing={1} sx={{ mt: 3 }}>
              {Object.entries(shownSummaries).map(
                ([expertName, productAnalysis]) => (
                  <SingleExpertProductSummary
                    key={expertName}
                    productAnalysis={productAnalysis}
                    expert={analysis.result.experts.find(
                      (a) => a.name === expertName,
                    )}
                  />
                ),
              )}
            </Stack>
            {Object.keys(hiddenSummaries).length > 0 && (
              <Box>
                <Divider sx={{ my: 2 }} />
                <Typography
                  color="primary.main"
                  fontWeight={600}
                  sx={{ mb: 2 }}
                >
                  No review{Object.keys(hiddenSummaries).length > 1 && 's'}{' '}
                  found for {formatList(Object.keys(hiddenSummaries))}
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </PaperAnalyze>
  );
}

function formatList(items: string[]) {
  const itemCount = items.length;

  if (itemCount === 0) {
    return '';
  } else if (itemCount === 1) {
    return items[0];
  } else if (itemCount === 2) {
    return items.join(' and ');
  } else {
    const lastItem = items.pop();
    return `${items.join(', ')}, and ${lastItem}`;
  }
}

type SingleExpertProductSummaryProps = {
  productAnalysis: ExpertProductAnalysis;
  expert: ExpertDefinition;
};
const SingleExpertProductSummary = ({
  productAnalysis,
  expert,
}: SingleExpertProductSummaryProps) => {
  const account_name = expert.name;
  const isAgent = expert.type === ExpertType.AGENT;
  const accountData = useGetPublicProfile(account_name, !isAgent);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  if (accountData.isError) return null; //<div>error</div>;

  const expertData = accountData.data;

  const markdownStyle = {
    fontSize: isSm ? 16 : 14,
    lineHeight: isSm ? '22px' : '19px',
  };

  return (
    <Box
      sx={{
        bgcolor: '#FFFFFF',
        p: 2,
        pl: 2.5,
        border: '1px solid #DCDCE3',
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: 10,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          bgcolor: getColor(
            getCommonRating(account_name, productAnalysis.score),
          ),
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      />
      <Stack direction="row" spacing={2} marginBottom={{ xs: 1, sm: 2 }}>
        <CountOnAvatar
          account_name={expertData?.account_name}
          isSleuth={isAgent}
          sx={{
            width: 50,
            height: 50,
            fontSize: 25,
            alignItems: 'center',
            cursor: 'pointer',
          }}
        />

        <Stack direction="column">
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            marginBottom={{ xs: 1, sm: 0 }}
          >
            <Typography
              component="a"
              sx={{
                fontWeight: 600,
                fontSize: 17,
                marginRight: { xs: 0, sm: 2 },
                marginBottom: { xs: 0.5, sm: 0 },
                textDecoration: 'none',
                minWidth: 100,
              }}
            >
              {isAgent
                ? expert.name
                : expertData?.display_name || <Skeleton animation="wave" />}
            </Typography>
          </Box>
          {!productAnalysis.title && (
            <Box display="flex" alignItems="center" marginTop={0.5}>
              <Info color="primary" sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography color="primary" fontSize="14px" fontWeight={600}>
                {isAgent ? 'Intern search results' : 'Brand review'}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} alignItems={'center'}>
        {showProductSentimentIcon(account_name) && (
          <SentimentIcon score={productAnalysis.score} />
        )}
        {productAnalysis.product_summary && (
          <ShowMoreMarkdownBox maxHeight={200} markdownStyle={markdownStyle}>
            {productAnalysis.product_summary}
          </ShowMoreMarkdownBox>
        )}
      </Stack>
      {/*<Box marginTop={{ xs: 1, sm: 2 }}>
          <ThumbsUpDownFeedback
            analyticsData={{
              schema: querySchemaForAnalytics,
              topic: 'review-card',
              context: 'detail_page',
              account_id: review.account_id,
              account_name: review.account_name,
              extension: false,
            }}
          />
        </Box>*/}
    </Box>
  );
};

const showProductSentimentIcon = (accountName: string) => {
  if (accountName === 'ewg') return true;
  return false;
};

const getCommonRating = (expertName: string, score?: ExpertScore) => {
  if (!score?.value) return 0;
  const ratingNames = ['unknown', 'green', 'yellow', 'red'];

  if (expertName === 'ewg' && Array.isArray(score.value)) {
    const upperBound = Number(score.value[1]);
    if (upperBound <= 2) return 1;
    if (upperBound <= 6) return 2;
    if (upperBound <= 10) return 3;
  }

  const simpleMatch = ratingNames.findIndex((rating) => rating === score.value);

  if (simpleMatch > -1) return simpleMatch;

  return 0;
};

const getColor = (rating: number) => {
  switch (rating) {
    case 3:
      return '#ef4444';
    case 2:
      return '#eab308';
    case 1:
      return '#22c55e';
    default:
      return '#e5e7eb';
  }
};

type SingleIngredientSummaryProps = {
  product_analysis_id: string;
  name: string;
  experts: { [key: string]: ExpertIngredientAnalysis };
};
const SingleIngredientSummary = ({
  product_analysis_id,
  name,
  experts,
}: SingleIngredientSummaryProps) => {
  const expertNames = Object.keys(experts);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          borderRadius: '10px 10px 0 0',
          px: 2,
          py: 1,
          bgcolor: 'primary.light2',
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{name}</Typography>
        <IngredientAudio ingredientName={name} />
      </Stack>
      <Stack
        direction="column"
        sx={{
          pl: { xs: 0.2, sm: 2 },
          py: 1,
          bgcolor: '#BEB4F910',
          borderRadius: '0 0 10px 10px',
        }}
        spacing={1}
      >
        {expertNames
          .filter((e) => experts[e])
          .map((expert) => (
            <GenericExpertIngredientSummary
              key={expert}
              expert={expert}
              product_analysis_id={product_analysis_id}
              analysis={experts[expert]}
              expertCount={expertNames.length}
            />
          ))}
      </Stack>
    </Box>
  );
};

const ICON_SIZE = 40;

type ExpertIngredientSummaryProps = {
  expert: string;
  product_analysis_id: string;
  analysis: ExpertIngredientAnalysis;
  expertCount: number;
};
const GenericExpertIngredientSummary = ({
  expert,
  product_analysis_id,
  analysis,
  expertCount,
}: ExpertIngredientSummaryProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [hover, setHover] = useState(false);
  const { user } = useAuth();

  let showSentimentIcon = analysis?.score && analysis.score.value !== 'unknown';
  if (expert === 'ireadlabelsforyou' && user?.role !== 'admin')
    showSentimentIcon = false;

  const hideLeftMargin = expertCount === 1 && !showSentimentIcon;
  const expertIngredientIcon = (
    <SentimentIcon
      sx={{ width: ICON_SIZE * 0.75, height: ICON_SIZE * 0.75 }}
      score={analysis.score}
    />
  );
  const formatSummary = (summary: string) => {
    // If the summary starts with [[unknown]], remove it
    if (summary.startsWith('[[unknown]]')) {
      return summary.slice(11);
    }
    return summary;
  };

  const mdStyle = {
    fontSize: isSm ? 16 : 14,
    lineHeight: isSm ? '22px' : '19px',
    marginTop: 0,
    paddingTop: 0,
  };

  return (
    <Stack
      direction="row"
      alignItems={'flex-start'}
      spacing={1}
      sx={{ position: 'relative' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hideLeftMargin && (
        <Box
          sx={{
            width: ICON_SIZE * 1.5,
            minWidth: ICON_SIZE * 1.5,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {showSentimentIcon && expertIngredientIcon}
        </Box>
      )}
      <ShowMoreMarkdownBox
        maxHeight={200}
        markdownStyle={mdStyle}
      >{`**${expert}**: ${formatSummary(
        analysis.summary,
      )}`}</ShowMoreMarkdownBox>
      <EvaluateWidget
        product_analysis_id={product_analysis_id}
        account_name={expert}
        analysis={analysis}
        show={hover}
        yOffset={analysis.summary.length > 500 ? 30 : 0}
      />
    </Stack>
  );
};

type ProductInformationProps = {
  analysis: SavedProductAnalysis;
};
function ProductInformation({ analysis }: ProductInformationProps) {
  const imageUrl = analysis.result.image_url;
  const imageRatio = imageUrl ? 0.25 : 0;
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 0 }}
      alignItems="center"
      justifyContent="center"
      width={1}
      sx={{ my: 0.5 }}
    >
      {imageUrl && (
        <Box
          width={{ xs: 1, sm: imageRatio }}
          height={{ xs: 250, sm: 250 }}
          sx={{ p: 1 }}
        >
          <ProductImage image_url={imageUrl} />
        </Box>
      )}
      <Box width={{ xs: 1, sm: 1 - imageRatio }}>
        <Stack direction="column" spacing={1}>
          {/*<Smile happy={true} />*/}
          {analysis.input.category && (
            <Typography fontSize={{ xs: '13px', sm: '16px' }}>
              <b>Category</b>: {analysis.input.category}
            </Typography>
          )}
          {analysis.input.description && (
            <Typography fontSize={{ xs: '13px', sm: '16px' }}>
              <b>Description from Website</b>: {analysis.input.description}
            </Typography>
          )}
          <Typography
            component="a"
            href={analysis.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              color: 'grey.600',
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {analysis.url}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
}
