import { Box, Button, Stack, Typography, Alert, Link } from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import {
  AnalysisMode,
  useCreateAnalysisStubMutation,
} from '../../hooks/ai/analyze-page-fetch';
import { ProductUrlFetcher } from './components/ProductUrlFetcher';
import {
  ManualProductData,
  ManualProductEntry,
} from './components/ManualProductEntry';
import { useQueryClient } from '@tanstack/react-query';
import log from 'loglevel';
import { useUrlArrayState, useUrlState } from '../../hooks/useUrlState';
import {
  useMostRecentlyCreatedAnalysisAccountNames,
  useReferrer,
} from '../../lib/hooksLib';
import SelectExpertList from './shared/SelectExpertList';
import { useAuth } from '../../hooks/use-auth';
import { useGetTrustedAccountsQuery } from '../../hooks/trusted-accounts-hooks';
import { useNavigate } from 'react-router-dom';
import { useSegment } from '../../contexts/segment-context';
import { Agent } from '../../types/agents';
import { useGetAgents } from '../../hooks/ai/agent-hooks';
import { useGetSavedProductAnalysis } from '../../hooks/ai/get-saved-product-analysis-hook';
import { ExpertType } from '../../types/saved_product_analyses';
import { v4 as uuidv4 } from 'uuid';
import LinkIcon from '@mui/icons-material/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { LoadingSpinner } from '../../components/loading-spinner';
import { canGuestAnalyze } from '../../lib/guest-analysis';
import { Link as RouterLink } from 'react-router-dom';

export default function AnalyzePage() {
  const navigate = useNavigate();
  const segment = useSegment();
  const { user, isGuest } = useAuth();
  const [urlAccountNames] = useUrlArrayState([], 'accounts');
  const [baseAnalysisId] = useUrlState('', 'base');
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();
  const baseAnalysisQuery = useGetSavedProductAnalysis(baseAnalysisId);
  const queryClient = useQueryClient();

  const { displayedAccounts, iniSelectedAccounts } = getInitialAccounts(
    trustedAccounts?.map((a) => a.account_name),
    baseAnalysisQuery.data?.account_names || urlAccountNames,
    defaultAccountNames,
  );

  // If coming from this saved analysis, this is already in the cache
  if (baseAnalysisId) log.trace(baseAnalysisQuery.data);

  const createAnalysisStubMutation = useCreateAnalysisStubMutation();

  // For guests, this immediately saves the account names in the URL in case they
  // create an account without actually conducting an analysis first
  useMostRecentlyCreatedAnalysisAccountNames();

  const [selectedAccountNames, setSelectedAccountNames] =
    useState<string[]>(iniSelectedAccounts);

  // Agent definitions
  const [agents, setAgents] = useState<Agent[]>([]);

  // If the user is logged in, pull their agents. If they are not and a base
  // analysis has been specified, use those agents and account names
  const userAgentsQuery = useGetAgents();
  useEffect(() => {
    if (userAgentsQuery.data?.agents) {
      setAgents(userAgentsQuery.data.agents);
    } else if (baseAnalysisId && baseAnalysisQuery.data) {
      const baseAgents = baseAnalysisQuery.data.result.experts
        .filter((e) => e.type === ExpertType.AGENT)
        .map((agent) => ({
          ...agent,
          agent_id: agent.agent_id || uuidv4(),
          task: agent.task || 'NEEDS DEFINITION', // Should always be there...
        }));
      setAgents(baseAgents);
    }
  }, [baseAnalysisId, baseAnalysisQuery.data, userAgentsQuery.data]);

  const iniNames = iniSelectedAccounts.join(',');
  useEffect(() => {
    setSelectedAccountNames(iniNames.split(','));
  }, [iniNames]);

  // If there's only a single account in the URL, assume that's a referrer
  const [, setReferrer] = useReferrer();
  useEffect(() => {
    if (urlAccountNames.length === 1) setReferrer(urlAccountNames[0]);
  }, [setReferrer, urlAccountNames]);

  // Whether to skip parsing the page and allow the user to enter information
  // directly
  const [manualMode, setManualMode] = useState(false);

  useEffect(() => {
    document.title = 'CountOn - Analyze a page';
  }, []);

  const handleStartAnalysis = (
    widgetUrl?: string,
    manualProductData?: ManualProductData,
  ) => {
    segment.track('Analysis Stub Started', {
      account_names: selectedAccountNames,
      mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
    });
    createAnalysisStubMutation.mutate(
      {
        url: widgetUrl,
        mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
        account_names: selectedAccountNames,
        agents: agents,
      },
      {
        onSuccess: (data) => {
          const analysisId = data.analysis.analysis_id;
          log.debug('Stub analysis finished', data);
          // Precache the query data so when the results page loads, it's read
          // to go
          queryClient.setQueryData(
            ['get-saved-analysis', analysisId],
            data.analysis,
          );
          segment.track('Analysis Stub Completed', {
            analysis_id: analysisId,
            account_names: selectedAccountNames,
            mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
          });
          // Load the results viewer page. With the state set, it will trigger
          // the full analysis, and the URL will then be set right so they can
          // reload the page or come back later and the analysis will continue.
          navigate(`/analyze-page/${analysisId}`, {
            state: {
              mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
              analysisStub: data.analysis,
              manualProductData,
              agents,
            },
          });
        },
      },
    );
  };

  const showSources = (user && trustedAccounts) || isGuest;
  const guestCanAnalyze = !isGuest || canGuestAnalyze();
  const disableAnalyze =
    createAnalysisStubMutation.isLoading ||
    !showSources ||
    selectedAccountNames?.length === 0 ||
    !guestCanAnalyze ||
    userAgentsQuery.isLoading;

  return (
    <>
      {isGuest && !guestCanAnalyze && (
        <Alert
          severity="error"
          sx={{
            width: '100%',
            borderRadius: 0,
            backgroundColor: '#FEE7E7',
            color: '#E53935',
            position: 'sticky',
            top: { xs: '78px', md: '74px' },
            zIndex: 1000,
            '& .MuiAlert-icon': {
              color: '#E53935',
              marginTop: 'auto',
              marginBottom: 'auto',
            },
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography fontWeight="bold" lineHeight={'20px'}>
              No analyses remaining.
            </Typography>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Link
                component={RouterLink}
                to="/signup"
                sx={{
                  color: 'inherit',
                  textDecoration: 'underline',
                  '&:hover': {
                    color: 'inherit',
                    opacity: 0.8,
                  },
                }}
              >
                Sign up for free to unlock unlimited analyses!
              </Link>
            </Box>
            <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
              Sign up for free to unlock unlimited analyses!
            </Typography>
          </Stack>
        </Alert>
      )}
      <SectionContainer
        backgroundColor="transparent"
        minHeight={1000}
        sx={{ py: { xs: 2, sm: 5 } }}
      >
        <Stack width={1} spacing={1} direction="column" alignItems={'center'}>
          <AnalysisHeader />
          <Stack
            direction="column"
            spacing={3}
            sx={{ width: 1, maxWidth: 1000 }}
          >
            <Stack
              direction="row"
              justifyContent={'center'}
              spacing={2}
              sx={{ my: 1 }}
            >
              <Button
                startIcon={
                  <LinkIcon
                    sx={{
                      transform: 'rotate(-45deg)',
                    }}
                  />
                }
                onClick={() => setManualMode(false)}
                sx={{
                  width: 200,
                  fontWeight: 600,
                }}
                variant="contained"
                color={manualMode ? 'info' : 'primary'}
              >
                Product URL
              </Button>
              <Button
                startIcon={<FormatListBulletedIcon />}
                onClick={() => setManualMode(true)}
                sx={{ width: 200, fontWeight: 600 }}
                variant="contained"
                color={!manualMode ? 'info' : 'primary'}
              >
                Manual Mode
              </Button>
            </Stack>
            {manualMode ? (
              <ManualProductEntry
                onAnalyzeClick={(mpd) => handleStartAnalysis(null, mpd)}
                disabled={disableAnalyze}
              />
            ) : (
              <ProductUrlFetcher
                onStart={handleStartAnalysis}
                disabled={disableAnalyze}
              />
            )}
            {showSources ? (
              <Box sx={{ pt: 3 }}>
                <SelectExpertList
                  accountNames={displayedAccounts}
                  selectedAccountNames={selectedAccountNames}
                  setSelectedAccountNames={setSelectedAccountNames}
                  agents={agents}
                  setAgents={setAgents}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <LoadingSpinner />
                <Typography sx={{ textAlign: 'center' }}>
                  Loading sources...
                </Typography>
              </Box>
            )}
          </Stack>
          {selectedAccountNames.length === 0 && (
            <Typography sx={{ pt: 5, fontSize: 22, fontWeight: 600 }}>
              Please select at least one expert to analyze a product
            </Typography>
          )}
          <Box sx={{ py: 4 }} />
          {/*<RecentProductAnalyses account_name={referrer} />*/}
        </Stack>
      </SectionContainer>
    </>
  );
}

const AnalysisHeader = () => {
  return (
    <Stack
      direction="column"
      alignItems={'center'}
      spacing={1}
      sx={{ width: 1, maxWidth: 1000, pb: 4 }}
    >
      <Typography
        fontSize={{ xs: 32, sm: 48 }}
        fontWeight={600}
        lineHeight={{ xs: 1.2, sm: 1.2 }}
      >
        See beyond labels
      </Typography>
      <Typography fontSize={{ xs: 16, sm: 22 }} fontWeight={500}>
        Instant, personalized ingredient analysis from sources you trust.
      </Typography>
    </Stack>
  );
};

const defaultAccountNames = ['ewg', 'gimmethegoodstuff', 'made-safe'];

function appendUnique<T>(array1: T[], array2: T[]): T[] {
  return array1.concat(array2.filter((item) => !array1.includes(item)));
}

const getInitialAccounts = (
  trustedAccounts: string[] | null,
  urlAccounts: string[],
  defaultAccounts: string[],
) => {
  // If trustedAccounts is empty, assume the user is not logged in
  const isGuest = !trustedAccounts || trustedAccounts.length === 0;
  const urlAccountsPresent = urlAccounts.length > 0;
  if (isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(urlAccounts, defaultAccounts),
      iniSelectedAccounts: urlAccounts,
    };
  if (isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: defaultAccounts,
      iniSelectedAccounts: defaultAccounts,
    };
  if (!isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(trustedAccounts, urlAccounts),
      iniSelectedAccounts: trustedAccounts,
    };
  if (!isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: trustedAccounts,
      iniSelectedAccounts: trustedAccounts,
    };
  throw new Error('Invalid state');
};
