import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Agent, AnalysisType } from '../../../types/agents';
import { v4 as uuidv4 } from 'uuid';
import { useSaveAgents } from '../../../hooks/ai/agent-hooks';
import ConfirmButton from '../../shared/ConfirmButton';

interface AgentWidgetProps {
  maxAgents?: number;
  agents: Agent[];
  setAgents?: React.Dispatch<React.SetStateAction<Agent[]>>;
  persistChanges?: boolean;
}

export function DefineAgents({
  maxAgents = 5,
  agents,
  setAgents,
  persistChanges = false,
}: AgentWidgetProps) {
  //const [agents, setAgents] = useState<Agent[]>([]);
  const [name, setName] = useState('');
  const [task, setTask] = useState('');
  const [type, setType] = useState<AnalysisType>(AnalysisType.PRODUCT);
  const [evalGuideline, setEvalGuideline] = useState('');
  const [domain, setDomain] = useState('');
  const [nameError, setNameError] = useState('');
  const [domainError, setDomainError] = useState('');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Agent | null>(null);
  const [editNameError, setEditNameError] = useState('');
  const [editDomainError, setEditDomainError] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const saveAgentsMutation = useSaveAgents();

  const validateName = (value: string) => {
    if (!value.trim()) {
      return 'Name is required';
    }

    const trimmedLength = value.trim().length;
    if (trimmedLength < 2) {
      return 'Name must be at least 2 characters';
    }
    if (trimmedLength > 30) {
      return 'Name must be 30 characters or less';
    }

    return '';
  };

  const extractDomain = (input: string): string => {
    if (!input.trim()) return '';

    try {
      // Add protocol if missing (required for URL constructor)
      const urlWithProtocol = input.includes('://') ? input : `http://${input}`;
      const urlObject = new URL(urlWithProtocol);
      return urlObject.hostname;
    } catch (error) {
      // If it's not a valid URL, try to clean it manually
      let cleaned = input.replace(/^(https?:\/\/)?(www\.)?/i, '');
      cleaned = cleaned.replace(/\/+$/, '');
      return cleaned;
    }
  };

  const validateDomain = (value: string) => {
    if (!value.trim()) {
      return ''; // Domain is optional
    }

    const domain = extractDomain(value);

    // Basic validation - must have at least one dot
    if (!domain || !domain.includes('.')) {
      return 'Please enter a valid domain (e.g., example.com)';
    }

    return '';
  };

  const handleAddAgent = (e: React.FormEvent) => {
    e.preventDefault();
    if (!setAgents) return;

    const nameError = validateName(name);
    const domainError = validateDomain(domain);

    if (nameError) {
      setNameError(nameError);
      return;
    }

    if (domainError) {
      setDomainError(domainError);
      return;
    }

    const newAgent: Agent = {
      agent_id: uuidv4(),
      name: name.trim(),
      task: task.trim(),
      eval_guideline: evalGuideline.trim(),
      analysis_type: type,
      domain: domain.trim() ? extractDomain(domain.trim()) : undefined,
    };

    const updatedAgents = [...agents, newAgent];
    setAgents(updatedAgents);
    setName('');
    setTask('');
    setEvalGuideline('');
    setDomain('');
    setNameError('');
    setDomainError('');
    setShowAddForm(false); // Hide the form after saving
    if (persistChanges) {
      saveAgentsMutation.mutate({ agents: updatedAgents });
    }
  };

  const handleRemoveAgent = (id: string) => {
    if (!setAgents) return;
    const updatedAgents = agents.filter((agent) => agent.agent_id !== id);
    setAgents(updatedAgents);
    if (persistChanges) {
      saveAgentsMutation.mutate({ agents: updatedAgents });
    }

    if (editingId === id) {
      setEditingId(null);
      setEditForm(null);
    }
  };

  const handleRemoveAll = () => {
    if (!setAgents) return;
    setAgents([]);
    setEditingId(null);
    setEditForm(null);
    // TODO: Show confirmation dialog
    if (persistChanges) {
      saveAgentsMutation.mutate({ agents: [] });
    }
  };

  const startEditing = (agent: Agent) => {
    if (!setAgents) return;
    setEditingId(agent.agent_id);
    setEditForm(agent);
    setEditNameError('');
    setEditDomainError('');
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditForm(null);
    setEditNameError('');
    setEditDomainError('');
  };

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!setAgents) return;
    if (!editForm) return;

    const nameError = validateName(editForm.name);
    const domainError = validateDomain(editForm.domain || '');

    if (nameError) {
      setEditNameError(nameError);
      return;
    }

    if (domainError) {
      setEditDomainError(domainError);
      return;
    }

    const updatedAgents = agents.map((agent) =>
      agent.agent_id === editingId
        ? {
            ...editForm,
            name: editForm.name.trim(),
            task: editForm.task.trim(),
            eval_guideline: editForm.eval_guideline?.trim(),
            domain: editForm.domain?.trim()
              ? extractDomain(editForm.domain.trim())
              : undefined,
          }
        : agent,
    );
    setAgents(updatedAgents);
    setEditingId(null);
    setEditForm(null);
    setEditNameError('');
    setEditDomainError('');
    if (persistChanges) {
      saveAgentsMutation.mutate({ agents: updatedAgents });
    }
  };

  return (
    <Paper sx={{ p: 3, borderRadius: 2 }} elevation={3}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6" color="textPrimary">
          Agents ({agents.length}/{maxAgents})
        </Typography>
        {setAgents && agents.length > 0 && (
          <ConfirmButton
            onConfirm={handleRemoveAll}
            variant="text"
            size="small"
            color="error"
            disabled={!!editingId}
          >
            Remove All
          </ConfirmButton>
        )}
      </Box>

      {/* Agent List */}
      {agents.length > 0 ? (
        <Stack spacing={2} mb={2}>
          {agents.map((agent) =>
            editingId === agent.agent_id && editForm ? (
              <Box
                key={agent.agent_id}
                component="form"
                onSubmit={handleEditSubmit}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: 'grey.300',
                  borderRadius: 2,
                  backgroundColor: 'grey.50',
                }}
              >
                <TextField
                  label="Name (2-30 characters)"
                  value={editForm.name}
                  onChange={(e) => {
                    setEditForm({ ...editForm, name: e.target.value });
                    if (editNameError)
                      setEditNameError(validateName(e.target.value));
                  }}
                  error={Boolean(editNameError)}
                  helperText={editNameError}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id={`edit-type-label-${agent.agent_id}`}>
                    This agent analyzes
                  </InputLabel>
                  <Select
                    labelId={`edit-type-label-${agent.agent_id}`}
                    value={editForm.analysis_type}
                    label="This agent analyzes"
                    onChange={(e) =>
                      setEditForm({
                        ...editForm,
                        analysis_type: e.target.value as AnalysisType,
                      })
                    }
                  >
                    <MenuItem value={AnalysisType.PRODUCT}>Products</MenuItem>
                    <MenuItem value={AnalysisType.INGREDIENT}>
                      Ingredients
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Domain (optional)"
                  value={editForm.domain || ''}
                  onChange={(e) => {
                    setEditForm({ ...editForm, domain: e.target.value });
                    if (editDomainError)
                      setEditDomainError(validateDomain(e.target.value));
                  }}
                  onBlur={(e) => {
                    // Clean domain when the field loses focus
                    const cleanedDomain = extractDomain(e.target.value);
                    setEditForm({ ...editForm, domain: cleanedDomain });
                    setEditDomainError(validateDomain(cleanedDomain));
                  }}
                  error={Boolean(editDomainError)}
                  helperText={
                    editDomainError ||
                    'Restrict this agent to analyze content from a specific domain (e.g., ewg.org)'
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Task"
                  value={editForm.task}
                  onChange={(e) =>
                    setEditForm({ ...editForm, task: e.target.value })
                  }
                  multiline
                  rows={3}
                  fullWidth
                  required
                  margin="normal"
                  placeholder={
                    editForm.analysis_type === AnalysisType.INGREDIENT
                      ? 'Describe what this agent will analyze about {ingredient}'
                      : 'Describe what this agent will analyze about the product'
                  }
                />
                <TextField
                  label="Evaluation Guideline"
                  value={editForm.eval_guideline}
                  onChange={(e) =>
                    setEditForm({
                      ...editForm,
                      eval_guideline: e.target.value,
                    })
                  }
                  multiline
                  rows={3}
                  fullWidth
                  required
                  margin="normal"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Button
                    type="button"
                    onClick={cancelEditing}
                    color="secondary"
                    startIcon={<CancelIcon />}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={
                      Boolean(editNameError) || Boolean(editDomainError)
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                key={agent.agent_id}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: 'grey.300',
                  borderRadius: 2,
                  backgroundColor: 'grey.50',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" color="textPrimary">
                      {agent.name}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {agent.analysis_type === AnalysisType.INGREDIENT
                        ? 'Ingredient Analysis'
                        : 'Product Analysis'}
                      {agent.domain && ` • Domain: ${agent.domain}`}
                    </Typography>
                  </Box>
                  {setAgents && (
                    <Box>
                      <IconButton
                        onClick={() => startEditing(agent)}
                        aria-label={`Edit ${agent.name}`}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRemoveAgent(agent.agent_id)}
                        aria-label={`Remove ${agent.name}`}
                        size="small"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Task:</strong> {agent.task}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    <strong>Evaluation Guideline:</strong>{' '}
                    {agent.eval_guideline}
                  </Typography>
                </Box>
              </Box>
            ),
          )}
        </Stack>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
            py: 3,
            mb: 2,
            backgroundColor: 'grey.50',
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: 1,
          }}
        >
          <Typography variant="body2" color="textSecondary">
            No agents configured yet
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Add an agent using the button below
          </Typography>
        </Box>
      )}

      {/* Add Agent Section */}
      {!editingId && agents.length < maxAgents && setAgents && (
        <>
          {!showAddForm ? (
            <Box sx={{ pt: 2, borderTop: 1, borderColor: 'grey.300' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAddForm(true)}
              >
                Add Agent
              </Button>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleAddAgent}
              sx={{ pt: 2, borderTop: 1, borderColor: 'grey.300' }}
            >
              <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                Add New Agent
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="add-type-label">Analysis Type</InputLabel>
                <Select
                  labelId="add-type-label"
                  value={type}
                  label="Analysis Type"
                  onChange={(e) => setType(e.target.value as AnalysisType)}
                >
                  <MenuItem value={AnalysisType.PRODUCT}>
                    Product Analysis
                  </MenuItem>
                  <MenuItem value={AnalysisType.INGREDIENT}>
                    Ingredient Analysis
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Name (2-30 characters)"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (nameError) setNameError(validateName(e.target.value));
                }}
                error={Boolean(nameError)}
                helperText={nameError}
                fullWidth
                margin="normal"
                placeholder="Agent name"
              />
              <TextField
                label="Domain (optional)"
                value={domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                  if (domainError)
                    setDomainError(validateDomain(e.target.value));
                }}
                onBlur={(e) => {
                  // Clean domain when the field loses focus
                  const cleanedDomain = extractDomain(e.target.value);
                  setDomain(cleanedDomain);
                  setDomainError(validateDomain(cleanedDomain));
                }}
                error={Boolean(domainError)}
                helperText={
                  domainError ||
                  'Restrict this agent to analyze content from a specific domain (e.g., ewg.org)'
                }
                fullWidth
                margin="normal"
                placeholder="example.com"
              />
              <TextField
                label="Task"
                value={task}
                onChange={(e) => setTask(e.target.value)}
                multiline
                rows={3}
                fullWidth
                required
                margin="normal"
                placeholder={
                  type === AnalysisType.INGREDIENT
                    ? 'Describe what this agent will analyze about {ingredient}'
                    : 'Describe what this agent will analyze about the product'
                }
              />
              <TextField
                label="Evaluation Guideline"
                value={evalGuideline}
                onChange={(e) => setEvalGuideline(e.target.value)}
                multiline
                rows={3}
                fullWidth
                required
                margin="normal"
                placeholder="Guidelines for evaluating this agent's output"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 2,
                  gap: 1,
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowAddForm(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={Boolean(nameError) || Boolean(domainError)}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}

      {/* Maximum Agents Reached Message */}
      {!editingId && agents.length >= maxAgents && setAgents && (
        <Box sx={{ pt: 2, borderTop: 1, borderColor: 'grey.300' }}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="body2">
              Maximum number of agents reached ({maxAgents})
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
