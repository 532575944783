import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import config from 'src/configs/aws-config';
//const BaseURL = "<http://localhost:8000>";
//const BaseURL =
//  'https://rpkrvem6lubersfvz3e6tqh6740mkocb.lambda-url.us-east-1.on.aws/';
const BaseURL = config.ai.URL;

const TEST_TEXT = `Full ingredient list
Click or tap an ingredient to learn more

Lolavie Restorative Shampoo 8.5 Oz:
lactobacillus/arundinaria gigantea ferment filtrate, sodium lauroyl methyl isethionate, lactobacillus ferment, cocamidopropyl betaine, glycerin, sodium cocoyl isethionate, sodium lauroyl sarcosinate, salvia hispanica (chia) seed extract, citric acid, glycosphingolipids, pisum sativum (pea) peptide, citrus limon (lemon) fruit extract, mentha piperita (peppermint) leaf extract, saccharum officinarum (sugar cane) extract, zingiber officinale (ginger) root extract, niacinamide, panthenol, saccharomyces/copper ferment, saccharomyces/iron ferment, saccharomyces/magnesium ferment, saccharomyces/zinc ferment, pyrus malus (apple) fruit extract, biotin, caffeine, melatonin, camellia sinensis leaf extract, hexapeptide-11, polyquaternium-10, betaine, fragrance (parfum)*, linalool, limonene`;

function App() {
  const [text, setText] = useState('');
  const [pyText, setPyText] = useState('');

  /*useEffect(() => {
    const getData = async () => {
      const result = await fetch(BaseURL, {
        method: 'POST',
        body: JSON.stringify({ text: TEST_TEXT }),
      });
      console.log({ result });
      const data = await result.text();
      console.log({ data });
      setText((prev) => prev + ' ' + data);
    };
    getData();
  }, []);*/

  //Testing SSE with lambda function URL
  /*useEffect(() => {
    //fetchStockPrice();
    console.log('testing');
    const eventSource = new EventSource(`${BaseURL}`);
    eventSource.addEventListener(
      'message',
      function (e) {
        console.log('test', e);
        setText((prev) => prev + ' ' + e.data);
      },
      false,
    );
    //eventSource.onmessage = (e) => {
    //  console.log('test', e);
    //};
    return () => {
      eventSource.close();
    };
  }, []);*/
  return (
    <Box>
      <h1>Test</h1>
      <p>{text}</p>
      <h1>Python</h1>
      <ReactMarkdown>{pyText}</ReactMarkdown>
    </Box>
  );
}
export default App;
