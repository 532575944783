import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Box, Divider, useScrollTrigger } from '@mui/material';
import Footer from '../Main/components/Footer';

import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';
import AnalysisTopbar from './components/AnalysisTopbar';
import AnalysisContainer from './components/AnalysisContainer';
import BlurredBox from './components/BlurredContainer';
import Sidebar from './components/Sidebar';

export default function ProductAnalyzerLayout({
  bgcolor = 'transparent',
  hideTopbar = false,
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const segment = useSegment();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const location = useLocation();
  useEffect(() => {
    segment.page('Main', location.pathname, { layout: 'main' });
  }, [location.pathname, segment]);

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5,
  });

  return (
    <Box>
      <ScrollRestoration />
      {!hideTopbar && (
        <AppBar
          position={'sticky'}
          sx={{
            top: 0,
            backgroundColor: trigger
              ? theme.palette.backgroundCustom.main
              : bgcolor,
          }}
          elevation={trigger ? 1 : 0}
        >
          <AnalysisContainer pt={0}>
            <AnalysisTopbar onSidebarOpen={handleSidebarOpen} />
          </AnalysisContainer>
          <Divider />
        </AppBar>
      )}

      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
      <Box sx={{ minHeight: '80vh' }}>
        <BlurredBox>
          <Outlet />
        </BlurredBox>
      </Box>
      <Divider />
      <AnalysisContainer py={4}>
        <Footer />
      </AnalysisContainer>
    </Box>
  );
}
