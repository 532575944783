import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { canGuestAnalyze } from '../../../lib/guest-analysis';

type NewAnalysisButtonProps = {
  baseAnalysisId?: string;
  urlAccountNames?: string[];
};
export default function NewAnalysisButton({
  baseAnalysisId,
  urlAccountNames,
}: NewAnalysisButtonProps) {
  const [openModal, setOpenModal] = useState(false);
  const { isGuest } = useAuth();

  let url = '/analyze-page';

  if (baseAnalysisId) {
    url += `?base=${baseAnalysisId}`;
  } else if (urlAccountNames) {
    url += `?accounts=${urlAccountNames.join(',')}`;
  } else {
    // Nothing
  }

  const handleClick = (e) => {
    if (isGuest && !canGuestAnalyze()) {
      e.preventDefault();
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        component={Link}
        to={url}
        startIcon={<AddCircleIcon />}
        variant="contained"
        onClick={handleClick}
      >
        <Typography fontWeight={600}>Analyze another product</Typography>
      </Button>

      <Dialog
        open={openModal}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{
          sx: { maxWidth: 450 },
        }}
      >
        <DialogTitle>
          <Typography fontSize={24} fontWeight={600}>
            You've used your 1 analysis this week
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>Sign up for FREE to unlock:</Typography>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckIcon color="primary" />
              <Typography>Unlimited analyses — no waiting</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckIcon color="primary" />
              <Typography>
                A complete analysis history to revisit anytime
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckIcon color="primary" />
              <Typography>
                Exclusive access to community insights and favorite products
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pb: 3, flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            component={Link}
            to="/signup"
            onClick={handleClose}
            sx={{ px: 4, py: 1 }}
          >
            Create a new account
          </Button>
          <Button onClick={handleClose}>Maybe later</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
