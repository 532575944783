const config = {
  SENTRY_DSN:
    'https://8b5167c7219f4e798267d7c7d3450b43@o4503943689207808.ingest.sentry.io/4503943692025857',
  GTM_CONFIG: {
    containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
    env: process.env.REACT_APP_STAGE,
  },
  SEGMENT_CONFIG: {
    writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  },
  GROWTHBOOK_CONFIG: {
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
    sdkKey: process.env.REACT_APP_GROWTHBOOK_SDK_KEY,
  },
  EXTENSION_CONFIG: {
    chromeExtensionUrl:
      //'https://chrome.google.com/webstore/detail/counton/lgbboilnfciafjafdcnohbjclcmbcmhm',
      'https://chrome.google.com/webstore/detail/counton-trusted-ratings-w/lgbboilnfciafjafdcnohbjclcmbcmhm',
    safariIOSExtensionUrl:
      'https://apps.apple.com/us/app/counton-values-based-shopping/id6451128414',
    chromeExtensionId: 'lgbboilnfciafjafdcnohbjclcmbcmhm',
    safariExtensionId: 'com.CountOn.Extension (B8SW5WPDY5)',
  },
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  trustableApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  ai: {
    //PYTHON_URL: process.env.REACT_APP_PYTHON_URL,
    PAGE_INSPECTOR_URL: process.env.REACT_APP_PAGE_INSPECTOR_URL,
    CORE_URL: process.env.REACT_APP_CORE_URL,
    LANGSMITH_SEARCH_URL: process.env.REACT_APP_LANGSMITH_SEARCH_URL,
  },
  websocket: {
    URL: process.env.REACT_APP_WEBSOCKET_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    STAGE: process.env.REACT_APP_STAGE,
  },
  clarity: {
    PROJECT_ID: process.env.REACT_APP_CLARITY_ID,
  },
};

export default config;
