import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import log from 'loglevel';
import SectionContainer from '../../components/SectionContainer';
import { useState } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { LoadingSpinner } from '../../components/loading-spinner';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  SearchAnalysesResponse,
  useSearchProductAnalyses,
} from '../../hooks/ai/search-product-analyses-hook';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { AnalysisStatus } from '../../types/saved_product_analyses';

export default function YourAnalyses() {
  const pageCount = 15;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');

  const { user } = useAuth();
  const analysisQuery = useSearchProductAnalyses({
    account_id: user.active_account_id,
    page_size: pageCount,
    page: page,
    query: search,
  });

  const handleSearchClick = () => {
    log.debug('searching for:', searchText);
    setSearch(searchText);
  };

  const handlePageChange = (_, value: number) => {
    setPage(value);
  };

  if (!user || analysisQuery.isLoading) return <LoadingSpinner />;

  if (analysisQuery.data?.n === 0 && search === '')
    return (
      <Stack direction="column" alignItems={'center'} spacing={2}>
        <NoAnalyses />
      </Stack>
    );

  if (analysisQuery.isError) return <div>Error</div>;

  const searchResults = analysisQuery.data;

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Stack direction="row" spacing={2} width={1}>
        <TextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          label="Search for..."
          fullWidth
          sx={{ maxWidth: 600 }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchClick();
            }
          }}
          InputProps={{
            endAdornment: (
              <Button
                onClick={handleSearchClick}
                disabled={
                  searchText === search ||
                  (analysisQuery.isLoading && analysisQuery.isFetching)
                }
                sx={{ minWidth: 'auto', padding: 0 }}
              >
                <SearchIcon />
              </Button>
            ),
          }}
        />
      </Stack>

      {searchResults.n === 0 ? (
        <Typography sx={{ my: 2 }}>No results</Typography>
      ) : searchResults.n > 0 ? (
        <Stack direction="column" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(searchResults.n / pageCount)}
            page={page}
            onChange={handlePageChange}
            sx={{ mb: 2, alignSelf: 'center' }}
          />
          {analysisQuery.data.analyses.map((result) => (
            <SearchResultCard key={result.analysis_id} searchResult={result} />
          ))}
          <Pagination
            count={Math.ceil(searchResults.n / pageCount)}
            page={page}
            onChange={handlePageChange}
            sx={{ mb: 2, alignSelf: 'center' }}
          />
        </Stack>
      ) : (
        <LoadingSpinner />
      )}
    </SectionContainer>
  );
}

interface SearchResultCardProps {
  searchResult: SearchAnalysesResponse;
}

export const SearchResultCard: React.FC<SearchResultCardProps> = ({
  searchResult,
}) => {
  const {
    analysis_id,
    brand,
    title,
    image_url,
    ingredients,
    experts,
    url,
    status,
    created_at,
  } = searchResult;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString + 'Z'); // Append 'Z' to indicate UTC time
    return isSm
      ? date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
  };

  const getStatusText = (status: AnalysisStatus) => {
    switch (status) {
      case AnalysisStatus.ANALYZING_INGREDIENTS:
        return 'In Progress';
      case AnalysisStatus.ANALYZING_PRODUCT:
        return 'In Progress';
      case AnalysisStatus.PRODUCT_ANALYZED:
        return 'Complete';
      case AnalysisStatus.ERROR:
        return 'Error';
      case AnalysisStatus.INGREDIENTS_ANALYZED:
        return 'Complete';
      case AnalysisStatus.INITIALIZED:
        return 'In Progress';
      default:
        return status;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'PENDING':
        return <AccessTimeIcon sx={{ fontSize: 20, color: 'warning.dark' }} />;
      case 'COMPLETED':
        return <CheckCircleIcon sx={{ fontSize: 20, color: 'success.dark' }} />;
      case 'ERROR':
        return <ErrorOutlineIcon sx={{ fontSize: 20, color: 'error.dark' }} />;
      default:
        return null;
    }
  };

  const getDisplayTitle = () => {
    if (!url) {
      // Manual analysis logic
      if (title) return title;
      if (brand) return brand;
      return 'Manual Analysis';
    }
    // URL-based analysis logic
    if (title) return title;
    return 'Untitled Analysis';
  };

  return (
    <Card
      sx={{
        display: 'flex',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: 1,
        boxShadow: 1,
        mb: 2,
      }}
    >
      {image_url ? (
        <CardMedia
          component="img"
          image={image_url}
          alt={title || 'Product image'}
          sx={{
            width: 96,
            objectFit: 'cover',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        />
      ) : (
        <Box
          sx={{
            width: 96,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.200',
            color: 'grey.500',
            fontSize: '0.875rem',
          }}
        >
          No Image
        </Box>
      )}
      <Box sx={{ flex: 1, p: 2, minWidth: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Link to={`/analyze-page/${analysis_id}`} title={getDisplayTitle()}>
              {getDisplayTitle()}
            </Link>
            {brand && title && (
              <Typography
                variant="body2"
                noWrap
                color="text.secondary"
                title={brand}
              >
                {brand}
              </Typography>
            )}
          </Box>
          <Box>
            <Chip
              icon={getStatusIcon(status)}
              label={getStatusText(status)}
              size="small"
              sx={{
                backgroundColor:
                  getStatusText(status) === 'Complete'
                    ? '#DCFCE7'
                    : getStatusText(status) === 'In Progress'
                      ? '#FEF3C7'
                      : '#FEE2E2',
                color:
                  getStatusText(status) === 'Complete'
                    ? '#166534'
                    : getStatusText(status) === 'In Progress'
                      ? '#92400E'
                      : '#991B1B',
              }}
            />
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={6} sm={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
              <CalendarTodayIcon sx={{ fontSize: 16, mr: 1 }} />
              <Typography variant="body2" noWrap color="text.secondary">
                {formatDate(created_at)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListIcon sx={{ fontSize: 16, mr: 1 }} />
              <Typography variant="body2" color="text.secondary" noWrap>
                {ingredients?.length || 0} ingredients
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PeopleIcon sx={{ fontSize: 16, mr: 1 }} />
              <Typography variant="body2" color="text.secondary" noWrap>
                {experts.length} experts
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            {url ? (
              <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                <OpenInNewIcon sx={{ fontSize: 16, mr: 1 }} />
                <Link to={url} target="_blank" rel="noopener noreferrer">
                  Source
                </Link>
              </Box>
            ) : (
              <Chip label="Manual" size="small" />
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

const NoAnalyses = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      mb={2}
      spacing={1}
    >
      <AutoAwesomeIcon sx={{ fontSize: 25, mb: 2 }} />
      <Typography fontWeight={500}>
        You haven't created any analyses yet.{' '}
      </Typography>
      <Typography>Once you do, you'll see them here.</Typography>
    </Stack>
  );
};
