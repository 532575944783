import log from 'loglevel';
import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetPageContentFromExtension } from '../../communication/extension-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../../hooks/use-auth';
import {
  AnalysisMode,
  useCreateAnalysisStubMutation,
} from '../../../../hooks/ai/analyze-page-fetch';
import { useNavigate } from 'react-router-dom';
import { useSegment } from '../../../../contexts/segment-context';
import { Agent } from '../../../../types/agents';
import { useGetAgents } from '../../../../hooks/ai/agent-hooks';

type AnalyzePageExtProps = {
  active: boolean;
};

export default function AnalyzePageExt({ active }: AnalyzePageExtProps) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const navigate = useNavigate();
  const segment = useSegment();

  // Whether the page has ever been active. This component can be loaded
  // but hidden. We don't want to kick off analyses unless the tab has
  // actually been shown
  const [everActive, setEverActive] = useState(active);
  useEffect(() => {
    if (active) setEverActive(true);
  }, [active]);

  // Agent definitions
  const [agents, setAgents] = useState<Agent[]>([]);

  // Get agents for the user
  const userAgentsQuery = useGetAgents();
  useEffect(() => {
    if (userAgentsQuery.data?.agents) {
      setAgents(userAgentsQuery.data.agents);
    }
  }, [userAgentsQuery.data]);

  const { pageContent } = useGetPageContentFromExtension();
  const { mutate: createAnalysisStub } = useCreateAnalysisStubMutation();

  // Helper function to get account names
  const getAccountNames = (): string[] => {
    return (
      user?.active_account?.trusted_accounts?.map((a) => a.account_name) || []
    );
  };

  const handleAnalyzeClick = () => {
    const accountNames = getAccountNames();
    segment.track('Analysis Stub Started', {
      account_names: accountNames,
      mode: AnalysisMode.MARKDOWN,
    });
    createAnalysisStub(
      {
        url: pageContent.url,
        account_names: accountNames,
        mode: AnalysisMode.MARKDOWN,
        agents: agents,
      },
      {
        onSuccess: (data) => {
          const analysisId = data.analysis.analysis_id;
          log.debug('Stub analysis finished', data);
          // Precache the query data so when the results page loads, it's read
          // to go
          queryClient.setQueryData(
            ['get-saved-analysis', analysisId],
            data.analysis,
          );
          segment.track('Analysis Stub Completed', {
            analysis_id: analysisId,
            account_names: accountNames,
            mode: AnalysisMode.MARKDOWN,
          });
          // Load the results viewer page. With the state set, it will trigger
          // the full analysis, and the URL will then be set right so they can
          // reload the page or come back later and the analysis will continue.
          navigate(`/analyze-page/${analysisId}`, {
            state: {
              mode: AnalysisMode.MARKDOWN,
              analysisStub: data.analysis,
              markdown: pageContent.markdown,
              agents: agents,
            },
          });
        },
      },
    );
  };

  const isButtonDisabled =
    userAgentsQuery.isLoading || getAccountNames().length === 0;

  log.trace('AnalyzePageExt', { active, everActive });

  return (
    <Stack direction="column" spacing={1} sx={{ p: 2 }}>
      <Button
        variant="contained"
        onClick={handleAnalyzeClick}
        disabled={isButtonDisabled}
      >
        Start Analysis
      </Button>
    </Stack>
  );
}
