import log from 'loglevel';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import { Agent } from '../../types/agents';
import { useAuth } from '../use-auth';

type SaveAgentsResponse = {
  agents: Agent[];
};

type SaveAgentsBody = {
  agents: Agent[];
};

function saveAgents(body: SaveAgentsBody): Promise<SaveAgentsResponse> {
  log.debug('SaveAgents', body);
  return API.post('trustable', '/agents', { body });
}

export function useSaveAgents(
  mutOptions?: UseMutationOptions<SaveAgentsResponse, Error, SaveAgentsBody>,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation<SaveAgentsResponse, Error, SaveAgentsBody>(
    (body: SaveAgentsBody) => saveAgents(body),
    {
      onSuccess: (data) => {
        log.debug('Save agents successful', data);
        queryClient.setQueryData(['get-all-agents'], data);
      },
      onError: (error) => {
        logError('Failed to save agents', error);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

function getAgents(): Promise<SaveAgentsResponse> {
  log.debug('Fetching agents');
  return API.get('trustable', '/agents', {});
}

export function useGetAgents() {
  const { isAuthenticated } = useAuth();
  const review = useQuery(['get-all-agents'], () => getAgents(), {
    enabled: isAuthenticated,
    staleTime: Infinity,
    onSuccess: (data) => log.debug('useGetAgents:', data),
    onError: (error) => logError(error),
  });
  return review;
}
