export enum AnalysisType {
  PRODUCT = 'product',
  INGREDIENT = 'ingredient',
}

export interface Agent {
  agent_id: string; // Should be a UUID
  name: string;
  task: string;
  analysis_type: AnalysisType;
  description?: string;
  eval_guideline?: string;
  domain?: string;
}
