// import { Box, useMediaQuery, useTheme } from '@mui/material';
// import Hero from './components/Hero';
// import HowItWorks from './components/HowItWorks';
// import GetStarted from './components/GetStarted';
// import Values from './components/Values';
// import FindYourExpert from './components/FindYourExpert';
// import { Stack } from '@mui/system';
import { useLocalStorage, useReferrer } from 'src/lib/hooksLib';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const EXPERT_KEY = 'onboarding-expert-list';

export default function Home() {
  // const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  useReferrer();

  // Set expert list from URL params. Needed for onboarding.
  const [searchParams] = useSearchParams();
  const [, setExpertList] = useLocalStorage(EXPERT_KEY, null);

  useEffect(() => {
    const expertListSp = searchParams.get('experts');
    if (expertListSp) setExpertList(expertListSp);
  }, [searchParams, setExpertList]);

  return <Navigate to="/analyze-page" />;

  // return (
  //   <Stack direction="column" spacing={0}>
  //     <BlurredBox blurSize={isMd ? 58 : 38}>
  //       <Hero />
  //     </BlurredBox>
  //     <Box sx={{ backgroundColor: '#FFFBF7' }}>
  //       <HowItWorks />
  //     </Box>
  //     <Box sx={{ backgroundColor: 'black.light' }}>
  //       <Values />
  //     </Box>
  //     <Box sx={{ backgroundColor: 'pastel.light' }}>
  //       <FindYourExpert />
  //     </Box>
  //     <Box sx={{ backgroundColor: 'black.light' }}>
  //       <GetStarted />
  //     </Box>
  //   </Stack>
  // );
}

// const BlurredBox = ({ blurSize, children }) => {
//   return (
//     <Box sx={{ position: 'relative', backgroundColor: '#FFFBF7' }}>
//       <Box
//         sx={{
//           position: 'absolute',
//           top: -90,
//           backgroundColor: '#FFF1E4',
//           filter: `blur(${blurSize}px)`,
//           height: '100%',
//           width: '100%',
//           zIndex: 0,
//         }}
//       />
//       <Box sx={{ position: 'relative', zIndex: 2 }}>{children}</Box>
//     </Box>
//   );
// };
