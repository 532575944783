import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { useAuth } from './use-auth';

import { API } from 'aws-amplify';
import { Account, TrustedAccount } from 'src/types/account';
import { OnboardingExpert } from 'src/types/onboarding';
import { Trust } from '../types/trust';

export function useGetTrustedAccountsQuery() {
  const { isAuthenticated, user } = useAuth();

  const query = useQuery<TrustedAccount[], Error>(
    ['trusted-accounts', user?.active_account_id],
    () => API.get('trustable', '/trusted-accounts', {}),
    {
      enabled: !!isAuthenticated && !!user?.active_account_id,
      staleTime: Infinity,
      onSuccess: (data) => {
        log.trace('Received trusted accounts', data);
      },
      onError: (error) => {
        logError(error);
      },
    },
  );

  return query;
}

export function useAddTrustedAccountMutation() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const mutation = useMutation(
    (trustee: Account | OnboardingExpert) => {
      const body = { trustee: trustee.account_id };
      return API.post('trustable', '/trusted-accounts', { body });
    },
    {
      onMutate: async (data: Account) => {
        // Optimistically assume we succeed and add trusted account
        await queryClient.cancelQueries(['trusted-accounts']);
        queryClient.setQueryData(
          ['trusted-accounts', user?.active_account_id],
          // priorAccounts might not exist, returning undefined bails out of the update
          (priorAccounts: Account[]) =>
            priorAccounts ? [...priorAccounts, data] : undefined,
        );
      },
      onSuccess: async (data) => log.debug('Add Account successful', data),
      onSettled: () => {
        queryClient.invalidateQueries(['trusted-accounts']);
        queryClient.invalidateQueries(['trust-recs']);
        //queryClient.invalidateQueries(['trusted-accounts', 'values']);
      },
    },
  );

  return mutation;
}

type AddTrustedAccountResponse = Trust[];
type AddTrustedAccountBody = {
  account_names?: string[];
};
const addTrustedAccounts = async (body: AddTrustedAccountBody) => {
  return API.post('trustable', '/trusted-accounts', { body });
};
export function useAddTrustedAccountsByNameMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    AddTrustedAccountResponse,
    Error,
    AddTrustedAccountBody
  >((body: AddTrustedAccountBody) => addTrustedAccounts(body), {
    onError: (error) => log.error(error),
    onSuccess: (data) => log.debug('Add Accounts successful', data),
    onSettled: () => {
      queryClient.invalidateQueries(['trusted-accounts']);
      queryClient.invalidateQueries(['trust-recs']);
    },
  });

  return mutation;
}

export function useDropTrustedAccountMutation() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const mutation = useMutation(
    (trustee: Account) => {
      return API.del(
        'trustable',
        `/trusted-accounts/${trustee.account_id}`,
        {},
      );
    },
    {
      onMutate: async (trustee: Account) => {
        await queryClient.cancelQueries(['trusted-accounts']);
        // Optimistically update to the new value by dropping the old trusted account
        queryClient.setQueryData(
          ['trusted-accounts', user?.active_account_id],
          (oldAccounts: Account[]) =>
            oldAccounts.filter((x) => x.account_id !== trustee.account_id),
        );
      },
      onSuccess: (data) => log.debug('Drop Account successful', data),
      onSettled: () => {
        queryClient.invalidateQueries(['trusted-accounts']);
        queryClient.invalidateQueries(['trust-recs']);
      },
    },
  );
  return mutation;
}

/*
// OLD
export function useAddTrustedAccountMutation() {
  const cache = useQueryClient();
  const mutation = useMutation(
    (trustee: Account) => {
      const body = { trustee: trustee.account_id };
      return API.post('trustable', '/trusted-accounts', { body });
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        log.error(error);
      },
      onSettled: () => {
        cache.invalidateQueries(['trust-recs']); // Won't be needed when recs move to ML
        cache.invalidateQueries(['trusted-users']);
      },
    }
  );
  return mutation;
}
*/
