import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useSegment } from '../../../contexts/segment-context';

interface ProductUrlFetcherProps {
  onStart: (url: string) => void;
  disabled: boolean;
}

export function ProductUrlFetcher({
  onStart,
  disabled: controlDisabled,
}: ProductUrlFetcherProps) {
  const [urlText, setUrlText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const segment = useSegment();

  const handleStart = () => {
    if (!isValidUrl(urlText)) return;
    segment.track('Page Fetch Started', { url: urlText });
    setDisabled(true);
    onStart(urlText);
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Stack direction="column" width={1} spacing={2}>
      <TextField
        fullWidth
        disabled={disabled}
        variant="outlined"
        placeholder="Past any product URL"
        aria-label="product URL"
        value={urlText}
        onChange={(e) => setUrlText(e.target.value)}
        rows={1}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleStart();
          }
        }}
        inputProps={{
          name: 'counton-analyze',
          style: inputStyle,
          enterKeyHint: 'analyze',
        }}
        InputProps={{
          endAdornment: urlText && !disabled && (
            <InputAdornment position="end" onClick={() => setUrlText('')}>
              <CloseIcon
                sx={{ color: 'black', fontSize: { xs: 20, md: 30 } }}
              />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '8px',
            p: 2,
          },
        }}
        sx={{
          backgroundColor: 'white !important', // Override EVERYTHING
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white !important', // No more transparency
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#aaa',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#888',
            },
          },
        }}
      />
      <Button
        variant="contained"
        onClick={handleStart}
        disabled={!isValidUrl(urlText) || disabled || controlDisabled}
        sx={{ fontWeight: 500, fontSize: 16 }}
      >
        Analyze Now
      </Button>
    </Stack>
  );
}

const inputStyle = {
  fontSize: 16,
  autocomplete: 'off',
  autocorrect: 'off',
  autocapitalize: 'none',
  spellcheck: 'false',
  padding: 0,
};
