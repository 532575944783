import { Box, Stack, TextField, Typography } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { PaperAnalyze } from '../shared/PaperAnalyze';

type IngredientsInspectorProps = {
  ingredients: string;
  setIngredients: (ingredients: string) => void;
  disabled?: boolean;
};
export function IngredientsInspector({
  ingredients,
  setIngredients,
  disabled = false,
}: IngredientsInspectorProps) {
  return (
    <PaperAnalyze sx={{ p: { xs: 1, sm: 2 }, width: 1 }}>
      <Stack direction="column">
        <Box>
          <TextField
            fullWidth
            value={ingredients}
            onChange={(e) => setIngredients(e.target.value)}
            multiline
            minRows={2}
            maxRows={8.6}
            disabled={disabled}
          />
        </Box>
        <Stack direction="row" spacing={1} alignItems={'center'} sx={{ mt: 2 }}>
          <LightbulbIcon color="primary" />
          <Box>
            <Typography
              color={'grey.800'}
              sx={{ lineHeight: '19px', fontSize: 14, fontWeight: 400 }}
            >
              Copy paste the ingredient list above
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </PaperAnalyze>
  );
}
