import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import logoUrl from '../../../assets/logos/CountOn_primary.png';
import { Button, Divider, MenuItem, MenuList } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { ProfileSelector } from './ProfileSelector';
import EmailIcon from '@mui/icons-material/Email';

const SidebarNav = ({ onClose }) => {
  const { isAuthenticated } = useAuth();

  const handleEmailClick = () => {
    const email = 'support@joincounton.com';
    window.location.href = `mailto:${email}`;
  };

  // Apologies for the goofy code duplication. Mui doesn't like items inside a
  // fragment so trying to avoid repeating the items common to both users and
  // guests proved more trouble than it was worth
  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component={Link}
          to="/"
          title="CountOn"
          width={{ xs: 100, md: 120 }}
        >
          <Box component={'img'} src={logoUrl} height={60} onClick={onClose} />
        </Box>
      </Box>
      <Box>
        {isAuthenticated ? (
          <MenuList>
            <MyMenuItem onClick={onClose} to="analysis-faq">
              FAQ
            </MyMenuItem>
            <Button
              size="medium"
              startIcon={<EmailIcon />}
              sx={{
                minWidth: 'max-content',
                fontWeight: 600,
                fontSize: '15px',
                color: 'black',
                ml: 1.3,
              }}
              onClick={handleEmailClick}
            >
              Get Help
            </Button>
            <Divider sx={{ mb: 1 }} />
            <ProfileSelector onChange={onClose} />
          </MenuList>
        ) : (
          <MenuList>
            <MyMenuItem onClick={onClose} to="analysis-faq">
              FAQs
            </MyMenuItem>
            <Button
              size="medium"
              startIcon={<EmailIcon />}
              sx={{
                minWidth: 'max-content',
                fontWeight: 600,
                fontSize: '15px',
                color: 'black',
                ml: 1.3,
              }}
              onClick={handleEmailClick}
            >
              Get Help
            </Button>
            <Divider />
            <MyMenuItem
              onClick={onClose}
              to="login"
              sx={{ color: 'primary.main' }}
            >
              Login
            </MyMenuItem>
            <MyMenuItem
              onClick={onClose}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white.main',
              }}
              to="signup"
            >
              Sign Up
            </MyMenuItem>
          </MenuList>
        )}
      </Box>
    </Box>
  );
};

export default SidebarNav;

const MyMenuItem = ({ to, children, sx = {}, onClick }) => {
  return (
    <MenuItem
      sx={{ ...sx, fontWeight: 700 }}
      component={Link}
      to={to}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
};
