import log from 'loglevel';
import { Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';

export enum PageInspectorStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export type ManualProductData = {
  brand?: string;
  title?: string;
  description?: string;
  ingredients?: string;
};

type PageInspectorProps = {
  onAnalyzeClick: (manualProductData: ManualProductData) => void;
  disabled?: boolean;
};
export function ManualProductEntry({
  onAnalyzeClick,
  disabled: controlDisabled = false,
}: PageInspectorProps) {
  const [manualProductData, setManualProductData] = useState<ManualProductData>(
    {},
  );
  const handleAnalyzeClick = async () => {
    log.debug('onAnalyzeClick');
    onAnalyzeClick(manualProductData);
  };

  // Function that takes a key and returns a function that sets the value of
  // that key in the manualProductData state
  const handleChange =
    (key: keyof ManualProductData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setManualProductData((prev) => ({
        ...prev,
        [key]: event.target.value,
      }));
    };

  const isDisabled =
    controlDisabled ||
    (!manualProductData.brand &&
      !manualProductData.title &&
      !manualProductData.description &&
      !manualProductData.ingredients);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={2}>
        <TextField
          onChange={handleChange('brand')}
          variant="outlined"
          placeholder="Brand"
          hiddenLabel
          sx={whiteSx}
        />
        <TextField
          onChange={handleChange('title')}
          placeholder="Product Title"
          hiddenLabel
          sx={whiteSx}
        />
        <TextField
          onChange={handleChange('description')}
          placeholder="Product Description"
          multiline
          maxRows={2.6}
          hiddenLabel
          sx={whiteSx}
        />
        <TextField
          onChange={handleChange('ingredients')}
          placeholder="Ingredients"
          helperText='You can just copy/paste the block of ingredients, no need to format it! Make sure to include the "* Organic" or other stuff that sometimes comes after the ingredient list.'
          multiline
          maxRows={8.6}
          minRows={3}
          hiddenLabel
          sx={whiteSx}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent={'center'}
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={handleAnalyzeClick}
          disabled={isDisabled}
          sx={{ fontWeight: 500, fontSize: 16 }}
        >
          Analyze Now
        </Button>
      </Stack>
    </Stack>
  );
}

const whiteSx = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white !important',
  },
};
