import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'src/components/loading-spinner';
import SectionContainer from 'src/components/SectionContainer';
import { useAccountSearch } from 'src/hooks/account-hooks';
import { useSearchParams } from 'react-router-dom';
import ExpertSearchTile from '../../shared/ExpertSearchTile';
import { useEffect, useState } from 'react';
import { useSegment } from '../../../contexts/segment-context';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import log from 'loglevel';

export default function GlobalVectorExpertsTab() {
  const [sp, setSp] = useSearchParams();

  const query = sp.get('q') || '';

  const expertQuery = useAccountSearch(query);

  const experts = expertQuery.data?.results;
  //console.log({ products, experts });

  return (
    <SectionContainer backgroundColor="pastel.light" minHeight="900px">
      <Stack spacing={2} direction="column" sx={{ mt: 2 }}>
        <SearchBar
          query={query}
          setQuery={(q) =>
            setSp((prevSp) => {
              prevSp.set('q', q);
              return prevSp;
            })
          }
        />
        {expertQuery.isLoading && <LoadingSpinner />}
        {experts?.length > 0 && (
          <Box>
            <Typography fontSize={15} fontWeight={600} sx={{ mb: 1 }}>
              {experts.length} expert{experts.length > 1 && 's'}{' '}
              {query && 'with a name like "' + query + '"'}
            </Typography>
            <AccountList accounts={experts.map((x) => x.account)} />
          </Box>
        )}
      </Stack>
    </SectionContainer>
  );
}

const AccountList = ({ accounts, query = '' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        gap: { xs: '10px', sm: '20px' },
        pb: 4,
      }}
    >
      {accounts.map((account) => (
        <ExpertSearchTile
          to={`/p/${account.account_name}${query ? '?cq=' + query : ''}`}
          key={account.account_id}
          account={account}
        >
          {account.n && (
            <Box>
              <Typography fontSize={15} fontWeight={500} component="span">
                {account.n} rating{account.n > 1 && 's'} in
              </Typography>
              <Typography
                color="primary"
                fontSize={15}
                fontWeight={600}
                component="span"
              >
                {' '}
                "{query}"
              </Typography>
            </Box>
          )}
        </ExpertSearchTile>
      ))}
    </Box>
  );
};

// const NoExpertNameMatch = ({ query }) => {
//   return (
//     <Box>
//       <Typography>No experts found with a name like "{query}"</Typography>
//     </Box>
//   );
// };

const SearchBar = ({ query, setQuery }) => {
  const [searchText, setSearchText] = useState('');
  const segment = useSegment();
  const [sp, setSp] = useSearchParams();

  // On page load, check if there is a query in the URL and set it in the search bar
  useEffect(() => {
    const q = sp.get('q');
    log.trace('topbar search', q);
    if (q) setQuery(q);
  }, [setQuery, sp]);

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  const handleSubmit = () => {
    segment.track('Search Submitted', {
      search_term: searchText,
      extension: false,
      context: 'website',
    });
    setQuery(searchText);
  };

  const clearSearch = () => {
    setSearchText('');
    setQuery('');
    setSp((priorSp) => {
      priorSp.delete('q');
      return priorSp;
    });
    log.info('TopBar cleared search');
  };

  const inputStyle = {
    fontSize: 16,
    autocomplete: 'off',
    autocorrect: 'off',
    autocapitalize: 'none',
    spellcheck: 'false',
    padding: 0,
  };

  return (
    <Stack
      direction="row"
      height={74}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <TextField
        fullWidth={true}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
        variant="filled"
        aria-label="search query"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        inputProps={{
          name: 'counton-search',
          autoFocus: true,
          style: inputStyle,
          enterKeyHint: 'search',
        }}
        InputProps={{
          hiddenLabel: true,
          disableUnderline: true,
          startAdornment: !searchText && (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'black', fontSize: 30 }} />
            </InputAdornment>
          ),
          endAdornment: searchText && (
            <InputAdornment position="end" onClick={clearSearch}>
              <CloseIcon
                sx={{ color: 'black', fontSize: { xs: 20, md: 30 } }}
              />
            </InputAdornment>
          ),
          sx: {
            borderRadius: 6,
            paddingX: searchText ? '16px' : '10px',
            paddingY: '10px',
          },
        }}
        sx={{
          maxWidth: 800,
          '& .MuiFilledInput-root': {
            backgroundColor: 'white !important',
          },
        }}
      />
    </Stack>
  );
};
