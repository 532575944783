export enum TabItem {
  REVIEWS = 'Reviews',
  EXPERTS = 'Experts',
  PRODUCTS = 'Products',
  CONTENT = 'Content',
  AI_SETTINGS = 'AI Settings',
  AI_ANALYSES = 'Analyses',
  FACTS = 'Facts',
  SAVES = 'Saves',
}
